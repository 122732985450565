import MyContextProvider from './context/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './components/Routes';

function App() {
  return (
    <MyContextProvider>
      <Router>
          <Routes/>
      </Router>
    </MyContextProvider>
  );
}

export default App;

