import React, { useContext, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {MyContext} from '../context/AuthContext';
import Cargando from './Cargando';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Adminsitracion from './Administracion';
 
export const PrivateRoute = ({ component: Component, ...rest}) => {
    const contexto = useContext(MyContext);
    const [toggledSidebar, toggleSidebar] = useState(false);
    const toggle = () => {
        toggleSidebar(!toggledSidebar);
    }

    return (
        <Route
        {...rest}
        render={ () => {
            return (contexto.rootState.currentUser === '') ? 
                    <Cargando/>
                :
                    (contexto.rootState.currentUser === null) ?
                        <Redirect to='/login'/>
                    :
                        <div className='app'>
                            <Sidebar toggledSidebar={toggledSidebar} toggleSidebar={toggle}/>
                            <main>
                                <div className="container-fluid p-0 m-0">
                                    <Navbar toggleSidebar={toggle}/>
                                    {
                                        contexto.rootState.idUsuario === '1' ? 
                                            <Adminsitracion/>
                                        :
                                            <Component/>
                                    }
                                </div>
                            </main>
                        </div> 
        }}
        >
        </Route>
    )
}