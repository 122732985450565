import React, { Component } from 'react';
import '../../../../css/calendar.css';
import colores from '../../../../css/bootstrap.scss';

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (this.props.showModal === false && (window.innerHeight > window.innerWidth)) {
            if (document.getElementsByClassName("calendar")[0]) {
                document.getElementsByClassName("calendar")[0].style.maxHeight = (height - 360) + 'px';
            }
        }else{
            if (this.props.showModal === false && (window.innerHeight < window.innerWidth)) {
                if (document.getElementsByClassName("calendar")[0]) {
                    document.getElementsByClassName("calendar")[0].style.minHeight = '150px';
                    document.getElementsByClassName("calendar")[0].style.maxHeight = (height - 360) + 'px';
                }
            }else{
                if (this.props.showModal === true) {
                    if (document.getElementsByClassName("calendar")[0]) {
                        document.getElementsByClassName("calendar")[0].style.maxHeight = (height - 60) + 'px';
                    }   
                }
            }
        }
    }

    formatDinero = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '$0.00';
        }else{
            return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber);
      
        return date.toLocaleString('es-MX', { month: 'long' });
      }

    getTotalResumen = (datos, peticion) => {
        let total = 0;
        if (peticion === 'ingresos') {            
            datos.forEach(element => {
                total = total + Number(element.ingresos);
            });
        }
        return this.formatDinero(total)
    }
    
    getPromedioResumen = (datos, peticion) => {
        let total = 0;
        if (peticion === 'ingresos') {            
            datos.forEach(element => {
                total = total + Number(element.ingresos);
            });
            total = (Number(total)/7).toFixed(2)
        }
        return this.formatDinero(total)
    }

    getMes = (index) => {
        let ocultar = false;
        if (index !== 0) {
            for (let i = 0; i < index; i++) {
                if (this.props.data[i].mes === this.props.data[index].mes) {
                    ocultar = true
                }
            }
        }
        return ocultar
    } 

    render() { 
        return ( 
            <div className="calendar elemento p-0" style={{ overflowX: 'auto' }}>
                <table className='table table-bordered m-0'>
                    <thead>
                        <tr className='dias' style={{ borderColor: 'rgba(101, 78, 124, 0.15)' }}>
                            <th style={{ width: '100px', backgroundColor: '#f9f9f9' }}></th>
                            <th style={{ width: '100px' }}>Lunes</th>
                            <th style={{ width: '100px' }}>Martes</th>
                            <th style={{ width: '100px' }}>Miercoles</th>
                            <th style={{ width: '100px' }}>Jueves</th>
                            <th style={{ width: '100px' }}>Viernes</th>
                            <th style={{ width: '100px' }}>Sabado</th>
                            <th style={{ width: '100px' }}>Domingo</th>
                            <th style={{ width: '110px', backgroundColor: 'rgba(101, 78, 124, 0.15)', whiteSpace: 'nowrap', borderRadius: '0px' }}>Venta Semanal</th>
                            <th style={{ width: '100px', backgroundColor: 'rgba(101, 78, 124, 0.15)', whiteSpace: 'nowrap', borderRadius: '0px' }}>Promedio</th>
                        </tr> 
                    </thead>
                    <tbody>
                        {this.props.data.map((element, index) => 
                            <tr key={index} style={{ borderColor: 'rgba(101, 78, 124, 0.15)' }}>
                                <td style={{ backgroundColor: '#f9f9f9', textTransform: 'capitalize' }}>
                                    <p className='monto' style={{ backgroundColor: colores.dark, color: 'white', fontSize: '8px' }} hidden={this.getMes(index)}>{element.mes}</p>
                                </td>
                                {element.map((eDia, indexDia) =>
                                    <td key={indexDia} className="p-0">
                                        <div className="row m-0">
                                            <div className="col-12" style={{ color: 'gray' }}>
                                                <p className='m-0 p-0'>{eDia.fechaObj.getDate()}</p>
                                            </div>
                                            <div className="col-12 text-end px-2" style={{ fontSize: '12px' }}>
                                                <p className="monto" style={{ border: '2px solid', borderColor: eDia.orden === 'mayor' ? '#367E18' : eDia.orden === 'menor' ? '#CC3636' : '#aaaaaa', color: eDia.orden === 'mayor' ? '#367E18' : eDia.orden === 'menor' ? '#CC3636' : '#aaaaaa', whiteSpace: 'nowrap' }}>{this.formatDinero(eDia.ingresos)}</p>
                                            </div>
                                        </div>
                                    </td>
                                )}
                                <td className='p-0' style={{ backgroundColor: 'rgba(101, 78, 124, 0.15)', borderColor: 'rgba(101, 78, 124, 0.15)' }}>
                                    <div className="row m-0">
                                        <div className="col-12">
                                            <p className='m-0 p-0' style={{ color: 'rgba(101, 78, 124, 0.15)' }}>-</p>
                                        </div>
                                        <div className="col-12 text-end px-2" style={{ fontSize: '12px' }}>
                                            <p className="monto" style={{ backgroundColor: 'transparent', border: '2px solid black', whiteSpace: 'nowrap' }}>{this.getTotalResumen(element, 'ingresos')}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='p-0' style={{ backgroundColor: 'rgba(101, 78, 124, 0.15)', borderColor: 'rgba(101, 78, 124, 0.15)', borderRadius: '0px' }}>
                                    <div className="row m-0">
                                        <div className="col-12">
                                            <p className='m-0 p-0' style={{ color: 'rgba(101, 78, 124, 0.15)' }}>-</p>
                                        </div>
                                        <div className="col-12 text-end px-2" style={{ fontSize: '12px' }}>
                                            <p className="monto" style={{ backgroundColor: 'transparent', border: '2px solid black', whiteSpace: 'nowrap' }}>{this.getPromedioResumen(element, 'ingresos')}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
         );
    }
}
 
export default Calendar;