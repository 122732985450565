import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import colores from '../../../css/bootstrap.scss';
import {MyContext} from '../../../context/AuthContext';
import * as XLSX from 'xlsx/xlsx.mjs';
import { MoonLoader } from 'react-spinners';
import SwitchSelector from 'react-switch-selector';

class CompararAnual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            desde: new Date().getFullYear() - 1,
            hasta: new Date().getFullYear(),
            data: [],
            saldos: [],
            cargando: true,
            vista: 'numeros'
        }
    }
    
    static contextType = MyContext;

    componentDidMount = () => {
        this.select();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.sucursal !== prevProps.sucursal) {
            this.select();
        }
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    select = async () => {
        this.setState({
            cargando: true
        })
        this.selectSaldo();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectAnualRango', desde: Number(this.state.desde), hasta: Number(this.state.hasta), sucursal: this.props.sucursal, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                data: data.datos,
            })
        }else{
            toast.error('Error al obtener la información.');
            console.log(data.mensaje);
        }
    }

    selectSaldo = async () => {
        let fechas = [];
        for (let index = this.state.desde; index <= this.state.hasta; index++) {
            fechas.push(this.formatDate(new Date(index, 11, 31).toLocaleDateString()));
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectSaldosDias', fechas: fechas, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                saldos: data.data,
                cargando: false
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambiarVista = (newValue) => {
        this.setState({
            vista: newValue
        })
    }

    commonProps = {
        height: 400,
        margin: { top: 30, right: 80, bottom: 30, left: 80 },
        indexBy: 'ano',
        labelSkipWidth: 16,
        labelSkipHeight: 16,
        enableGridX: true,
        enableGridY: false,
        labelTextColor: 'white',
        axisTop: {
            tickSize: 0,
            tickPadding: 12,
        },
        axisBottom: {
            legend: 'Meses',
            legendPosition: 'middle',
            legendOffset: 50,
            tickSize: 0,
            tickPadding: 12,
        },
        axisLeft: {
            format: (v) => `$ ${(this.numberWithCommas(v))}`,
        },
        axisRight: {
            format: (v) => `$ ${(this.numberWithCommas(v))}`,
        },
        markers: [
            {
                axis: 'y',
                value: 0,
                lineStyle: { stroke: colores.dark, strokeWidth: 1 },
                textStyle: { fill: colores.dark },
            },
        ],
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        return height - 70
    }

    buscar = (e) => {
        e.preventDefault();
        this.select();
    }

    getUtilidades = (ingresos, costosventa, gastosoperativos) => {
        let utilidades = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos))
        return utilidades
    }
    
    getBalance = (ingresos, costosventa, gastosoperativos, repartoutilidades, inversiones) => {
        let disponible = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos) + Math.abs(repartoutilidades) + Math.abs(inversiones))
        return disponible
    }

    ExportToExcel(type, fn, dl) {
        var elt = document.getElementById('tbl_exporttable_to_xls');
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
        return dl ?
        XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
        XLSX.writeFile(wb, fn || ('Resumen comparativo anual ' + this.state.hasta + '/' + this.state.desde + '.' + (type || 'xlsx')));
     }

    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Math.abs(Number(parte));
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }

    getTotal = (tipoRegistro) => {
        let total = 0;
        this.state.data.forEach(element => {
            total = total + Math.abs(Number(element[tipoRegistro]));
        }); 
        return total
    }

    getTotalUtilidades = () => {
        let total = 0;
        let ingresos = 0;
        let costosventa = 0;
        let gastosoperativos = 0;
        this.state.data.forEach(element => {
            ingresos = ingresos + Math.abs(Number(element.ingresos));
            costosventa = costosventa + Math.abs(Number(element.costosventa));
            gastosoperativos = gastosoperativos + Math.abs(Number(element.gastosoperativos));
        });
        total = ingresos - (costosventa + gastosoperativos);
        return total
    }
    
    getTotalBalance = () => {
        let total = 0;
        let ingresos = 0;
        let costosventa = 0;
        let gastosoperativos = 0;
        let inversiones = 0;
        let repartoutilidades = 0;
        this.state.data.forEach(element => {
            ingresos = ingresos + Math.abs(Number(element.ingresos));
            costosventa = costosventa + Math.abs(Number(element.costosventa));
            gastosoperativos = gastosoperativos + Math.abs(Number(element.gastosoperativos));
            inversiones = inversiones + Math.abs(Number(element.inversiones));
            repartoutilidades = repartoutilidades + Math.abs(Number(element.repartoutilidades));
        });
        total = ingresos - (costosventa + gastosoperativos + inversiones + repartoutilidades);
        return total
    }

    getPromedio = (tipoRegistro) => {
        let divisor = (this.state.hasta - this.state.desde) + 1;
        let promedio = this.getTotal(tipoRegistro)/divisor;
        return promedio
    }
    
    getPromedioUtilidades = () => {
        let divisor = (this.state.hasta - this.state.desde) + 1;
        let promedio = this.getTotalUtilidades()/divisor;
        return promedio
    }
    
    getPromedioBalance = () => {
        let divisor = (this.state.hasta - this.state.desde) + 1;
        let promedio = this.getTotalBalance()/divisor;
        return promedio
    }
    
    render() { 
        return ( 
            <>
                <div className={"col-4 py-0 px-1 " + (this.props.sucursal ? 'mt-2' : '')}>
                    <div className="inputGroup">
                        <label htmlFor="desde">Desde</label>
                        <input type="number" id='desde' className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '16px' }} name='desde' min={2020} max={Number(this.state.hasta) - 1} value={this.state.desde} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-1">
                    <div className="inputGroup">
                        <label htmlFor="hasta">Hasta</label>
                        <input type="number" id='hasta' className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '16px' }} name='hasta' placeholder='Hasta' min={Number(this.state.desde) + 1} max={2099} value={this.state.hasta} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-1">
                    <button className='w-100 btnAplicar' onClick={this.buscar}>Aplicar</button>
                </div>         
                {
                    this.state.cargando ? 
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="text-start">
                                <MoonLoader color={colores.primary} size={200}/>
                            </div>
                        </div>
                    :
                        <div className='contenido px-3'>
                            <div className="mt-3 mx-auto" style={{ height: '40px', width: '200px' }}>
                                <SwitchSelector
                                    forcedSelectedIndex={[{label: "Datos", value: "numeros"},{label: "Gráficos", value: "graficos"}].findIndex(e => e.value === this.state.vista)}
                                    onChange={this.cambiarVista}
                                    wrapperBorderRadius={5}
                                    optionBorderRadius={5}
                                    backgroundColor={colores.primary}
                                    selectedBackgroundColor={colores.dark}
                                    options={[
                                        {label: <i className="bi bi-table text-white"></i>, value: "numeros"},
                                        {label: <i className="bi bi-pie-chart text-white"></i>, value: "graficos"}
                                    ]}
                                />
                            </div>
                            {
                                this.state.vista === 'numeros' ?
                                    <>
                                        <div className="mt-2" style={{ overflowX: 'auto' }}>
                                            <table id='tbl_exporttable_to_xls'>
                                                <thead>
                                                    <tr className=''>
                                                        <th className='stickyTH'></th>
                                                        {
                                                            this.state.data.map((e) =>
                                                                [
                                                                    <th key={1} colSpan={1}>{e.ano}</th>,
                                                                ]
                                                            )
                                                        }
                                                        <th colSpan={2}>Total</th>
                                                        <th>Prom. Anual</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#D1FFE3' }}><i className="bi bi-cash-coin"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Ingresos"} readOnly/>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.data.map((e) =>
                                                            [
                                                                <td key={1} colSpan={1}>$ {this.numberWithCommas(e.ingresos)}</td>,
                                                            ]
                                                            )
                                                        }
                                                        <td>$ {this.numberWithCommas(this.getTotal('ingresos'))}</td>
                                                        <td>{this.getPorcentaje(this.getTotal('ingresos'), this.getTotal('ingresos'))}%</td>
                                                        <td>$ {this.numberWithCommas(this.getPromedio('ingresos'))}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#FEF6FF' }}><i className="bi bi-wallet2"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Costo de venta"} readOnly/>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.data.map((e) =>
                                                            [
                                                                <td key={1} colSpan={1}>$ {this.numberWithCommas(Math.abs(e.costosventa))}</td>,
                                                            ]
                                                            )
                                                        }
                                                        <td>$ {this.numberWithCommas(this.getTotal('costosventa'))}</td>
                                                        <td>{this.getPorcentaje(this.getTotal('ingresos'), this.getTotal('costosventa'))}%</td>
                                                        <td>$ {this.numberWithCommas(this.getPromedio('costosventa'))}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#FFFADE' }}><i className="bi bi-droplet"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Gastos operativos"} readOnly/>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.data.map((e) =>
                                                            [
                                                                <td key={1} colSpan={1}>$ {this.numberWithCommas(Math.abs(e.gastosoperativos))}</td>,
                                                            ]
                                                            )
                                                        }
                                                        <td>$ {this.numberWithCommas(this.getTotal('gastosoperativos'))}</td>
                                                        <td>{this.getPorcentaje(this.getTotal('ingresos'), this.getTotal('gastosoperativos'))}%</td>
                                                        <td>$ {this.numberWithCommas(this.getPromedio('gastosoperativos'))}</td>
                                                    </tr>
                                                    <tr style={{ borderTop: '2px solid gray' }} className='text-end'>
                                                        <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-cash"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Utilidades"} readOnly/>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.data.map((e) =>
                                                            [   
                                                                <td key={1} style={{ paddingBlock: '0px' }}>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            $ {this.numberWithCommas(this.getUtilidades(e.ingresos, e.costosventa, e.gastosoperativos))}
                                                                        </div>
                                                                        <div className="col-12">
                                                                            {this.getPorcentaje(e.ingresos, this.getUtilidades(e.ingresos, e.costosventa, e.gastosoperativos))}%
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            ]
                                                            )
                                                        }                                    
                                                        <td>$ {this.numberWithCommas(this.getTotalUtilidades())}</td>
                                                        <td>{this.getPorcentaje(this.getTotal('ingresos'), this.getTotalUtilidades())}%</td>
                                                        <td>$ {this.numberWithCommas(this.getPromedioUtilidades())}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#D3E5EF' }}><i className="bi bi-cash-stack"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Reparto utilidades"} readOnly/>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.data.map((e) =>
                                                            [
                                                                <td key={1} colSpan={1}>$ {this.numberWithCommas(Math.abs(e.repartoutilidades))}</td>,
                                                            ]
                                                            )
                                                        }
                                                        <td>$ {this.numberWithCommas(this.getTotal('repartoutilidades'))}</td>
                                                        <td>{this.getPorcentaje(this.getTotal('ingresos'), this.getTotal('repartoutilidades'))}%</td>
                                                        <td>$ {this.numberWithCommas(this.getPromedio('repartoutilidades'))}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#f9d4ea' }}><i className="bi bi-graph-up-arrow"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Inversiones"} readOnly/>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.data.map((e) =>
                                                            [
                                                                <td key={1} colSpan={1}>$ {this.numberWithCommas(Math.abs(e.inversiones))}</td>,
                                                            ]
                                                            )
                                                        }
                                                        <td>$ {this.numberWithCommas(this.getTotal('inversiones'))}</td>
                                                        <td>{this.getPorcentaje(this.getTotal('ingresos'), this.getTotal('inversiones'))}%</td>
                                                        <td>$ {this.numberWithCommas(this.getPromedio('inversiones'))}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-graph-up"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Balance"} readOnly/>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.data.map((e) =>
                                                            [
                                                                <td key={1} colSpan={1}>$ {this.numberWithCommas(this.getBalance(e.ingresos, e.costosventa, e.gastosoperativos, e.repartoutilidades, e.inversiones))}</td>,
                                                            ]
                                                            )
                                                        }
                                                        <td>$ {this.numberWithCommas(this.getTotalBalance())}</td>
                                                        <td>{this.getPorcentaje(this.getTotal('ingresos'), this.getTotalBalance())}%</td>
                                                        <td>$ {this.numberWithCommas(this.getPromedioBalance())}</td>
                                                    </tr>
                                                    <tr className='text-end'>
                                                        <td className='tdConcepto sticky'>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={{ backgroundColor: '#eaeaea' }}><i className="bi bi-bank"></i></span>
                                                                </div>
                                                                <input type="text" className="form-control d-flex my-auto ms-1" value={"Disponible"} readOnly/>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.saldos.map((e) =>
                                                            [
                                                                <td key={1} colSpan={1}>$ {this.numberWithCommas(e)}</td>,
                                                            ]
                                                            )
                                                        }
                                                                <td colSpan={4}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button onClick={() => this.ExportToExcel('xlsx')} className='btn mt-1 mb-4' style={{ padding: '0px', textDecoration: 'underline', fontSize: '14px', color: 'gray' }}>Descargar tabla</button>
                                    </>
                                :
                                    <div className='my-4 elemento'>
                                        <h6 className='m-0'>Este reporte no cuenta con gráficos.</h6>
                                    </div>
                            }
                        </div>
                }
                <Toaster/>
            </>
         );
    }
}
 
export default CompararAnual;