import React, { useState } from 'react';
import { auth } from '../firebase';
 
function RestablecerContrasena () {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [mensaje, setMensaje] = useState('');
 
  const handleEmail = (e) => setEmail(e.target.value);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
        await auth.sendPasswordResetEmail(email, null);
        setMensaje('Correo para cambiar contraseña enviado, si no lo encuenta, revise su bandeja de spam.');
    }catch (error) {
        switch (error.code) {
            case 'auth/internal-error':
                setError('Error interno');
                break;
            case 'auth/user-not-found':
                setError('No se encuentra una cuenta asociada a este correo electrónico');
                break;
            default:
                setError(error.code);
                break;
        }
    }
  }

  return (
    <>
    <div className="container-fluid d-flex align-items-center fondoLogin" style={{ height: '100vh' }}>
        <div className='px-4 mx-auto' style={{ maxWidth: '500px', width: '100%' }}>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card text-white" style={{ backgroundColor: 'rgba(0,0,0,0.15)', border: 'none', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                        <div className="card-header text-center py-3">
                            <h1 className='m-0'>Restablecer Contraseña</h1>
                        </div>
                        <div className="card-body">
                            {
                                mensaje === '' ?
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-floating">
                                            <input type="email" name='email' id='email' className="form-control inputUnderline text-white" style={{ borderBottom: '2px solid white', borderRadius: '0px' }} placeholder="Email" required onChange={handleEmail}/>
                                            <label htmlFor="email">Correo electrónico</label>
                                        </div>
                                        <button type="submit" className="btnRadius boxShadow px-4 d-flex mx-auto mt-4 btn btn-outline-light" style={{ width: 'fit-content', fontSize: '18px' }}>Restablecer</button>
                                    </form>
                                :
                                    <div>
                                        <p className='m-0'>{mensaje}</p>
                                        <a className='btnRadius boxShadow px-4 d-flex mx-auto mt-4 btn btn-outline-light' style={{ width: 'fit-content', fontSize: '18px' }} href="/login">Iniciar Sesión</a>
                                    </div>
                            }
                        </div>
                        <div className="card-footer text-center" style={{ borderColor: 'rgba(255,255,255,0.2)' }}>
                            <div className="row">
                                <div className="col-12">                                    
                                    {error && <p className='error m-0' style={{ color: '#ffa5a5' }}>{error}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
 
}
 
export default RestablecerContrasena