import React, { Component } from 'react';
import colores from '../../../css/bootstrap.scss';
import toast, { Toaster } from 'react-hot-toast';
import {MyContext} from '../../../context/AuthContext';
import { MoonLoader } from 'react-spinners';

class SaldoDiario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fecha: this.formatDate(new Date().toLocaleDateString()),
            data: [],
            cargando: true
        }
    }
    
    static contextType = MyContext;

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    componentDidMount = async () => {
        this.select();
    }

    select = async () => {
        this.setState({
            cargando: true
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectSaldoDia', fecha: this.state.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                data: data.data,
                cargando: false
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    buscar = () => {
        this.select();
    }

    getTotal = () => {
        let total = 0;
        let cuentas = this.state.data;
        cuentas.forEach(element => {
            total = total + Number(element.saldo)
        });
        return total
    }

    getTotalEfectivo = () => {
        let total = 0;
        let cuentas = this.state.data;
        cuentas = cuentas.filter(e => e.nombreCuenta.toLowerCase().includes('efectivo'));
        cuentas.forEach(element => {
            total = total + Number(element.saldo)
        });
        return total
    }
    
    render() { 
        return ( 
            <>
                <div className="col-4 py-0 px-1">
                    <div className="inputGroup">
                        <label htmlFor="fecha">Fecha</label>
                        <input type="date" className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '16px' }} name="fecha" id="fecha" value={this.state.fecha} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-2">
                    <button className='w-100 btnAplicar' onClick={this.buscar}>Aplicar</button>
                </div>
                {
                    this.state.cargando ? 
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="text-start">
                                <MoonLoader color={colores.primary} size={200}/>
                            </div>
                        </div>
                    :
                        <div className='contenido px-3' style={{ fontSize: '14px' }}>                            
                            <div className="elemento mt-3">
                                <div className="row">
                                    {
                                        this.state.data.map((e, index) =>
                                            <div className="col-6 px-3" key={index}>
                                                <div className={"row elemento px-0 py-2 mb-2 " + ((e.idCuenta === '1') ? 'border border-secondary' : '') }>
                                                    <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                                        <p className='m-0' style={{ fontSize: '12px' }}>{e.nombreCuenta}</p>
                                                    </div>
                                                    <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                                        <p className='m-0'>$ {this.numberWithCommas(e.saldo)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <hr className='my-1'/>
                                <div className="row mt-2">
                                    <div className="col-6 px-3">
                                        <div className="row elemento px-0 py-2 mb-2 bg-primary" style={{ color: 'white' }}>
                                            <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                                <p className='m-0' style={{ fontSize: '12px' }}>Total Efectivo</p>
                                            </div>
                                            <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                                <p className='m-0'>$ {this.numberWithCommas(this.getTotalEfectivo())}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 px-3">
                                        <div className="row elemento px-0 py-2 bg-info" style={{ color: 'white' }}>
                                            <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                                <p className='m-0' style={{ fontSize: '12px' }}>Total</p>
                                            </div>
                                            <div className="col-12 m-0 d-flex align-items-center justify-content-center">
                                                <p className='m-0'>$ {this.numberWithCommas(this.getTotal())}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                <Toaster/>
            </>
         );
    }
}
 
export default SaldoDiario;