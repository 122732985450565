import React from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SidebarFooter, SubMenu } from 'react-pro-sidebar';
import '../css/sidebar.scss';
import '../css/sidebar.css';
import { MyContext } from '../context/AuthContext';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: ''
        }
    }

    static contextType = MyContext;

    componentDidMount = () => {

    }

    CerrarSesion = () => {

    }

    render() { 
        return ( 
            <ProSidebar breakPoint='md' toggled={this.props.toggledSidebar} onToggle={this.props.toggleSidebar}>
                <SidebarHeader className='py-1 bg-dark' style={{ height: '50px', borderWidth: '2px' }}>
                    <div className="row m-0">
                        <div className="col-2 d-flex align-items-center">                            
                            <img src={"/img/logo" + this.context.rootState.nombre + ".png"} alt="Logo" onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/img/default.jpg"; }} style={{ height: '40px', width: 'auto', borderRadius: '100%' }} className=''/>
                        </div>
                        <div className="col-10 d-flex align-items-center">
                            <p className='m-0 p-0'>{this.context.rootState.email}</p>
                        </div>
                    </div>
                </SidebarHeader>
                <SidebarContent className='sidebarContentBg'>
                    <Menu>
                        <MenuItem icon={<i className="bi bi-house-fill" style={{ fontSize: '20px' }}></i>}>
                            <a href="/">Inicio</a>
                        </MenuItem>
                        <MenuItem icon={<i className="bi bi-pen-fill" style={{ fontSize: '20px' }}></i>} hidden={this.context.rootState.idUsuario === '1'}>
                            <a href="/registrar">Registrar</a>
                        </MenuItem>
                        <MenuItem icon={<i className="bi bi-pie-chart-fill" style={{ fontSize: '20px' }}></i>} hidden={this.context.rootState.idUsuario === '1'}>
                            <a href="/registros">Reportes</a>
                        </MenuItem>
                        <SubMenu title={"Configuración"} icon={<i className="bi bi-gear-fill" style={{ fontSize: '20px' }}></i>} hidden={this.context.rootState.idUsuario === '1'}>
                            <MenuItem icon={<i className="bi bi-person"></i>}>
                                <a href="/configuracion/usuario">Usuario</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-image"></i>}>
                                <a href="/configuracion/logo">Logo</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-building"></i>}>
                                <a href="/configuracion/sucursales">Sucursales</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-wallet2"></i>}>
                                <a href="/configuracion/cuentas">Cuentas</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-box-seam"></i>}>
                                <a href="/configuracion/productos">Productos</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-graph-down-arrow"></i>}>
                                <a href="/configuracion/tiposcostoventa">Tipos de Costo de Venta</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-droplet"></i>}>
                                <a href="/configuracion/servicios">Servicios</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-cash-coin"></i>}>
                                <a href="/configuracion/tiposrepartoutilidades">Tipos de Reparto de Utilidades</a>
                            </MenuItem>
                            <MenuItem icon={<i className="bi bi-graph-up"></i>}>
                                <a href="/configuracion/tiposinversion">Tipos de Inversión</a>
                            </MenuItem>
                        </SubMenu>
                    </Menu>
                </SidebarContent>
                <SidebarFooter className='p-2 text-center bg-secondary' style={{ border: 'none' }}>
                    <button onClick={() => window.location.reload()} type='button' className='btn px-3 mx-2' style={{ border: 'none', color: 'white', backgroundColor: 'rgba(0,0,0,0.1)' }}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </button>
                    <button onClick={() => this.context.logout()} type='button' className='btn px-3 mx-2' style={{ border: 'none', color: 'white', backgroundColor: 'rgba(0,0,0,0.1)' }}>
                        <i className="bi bi-box-arrow-left"></i>
                    </button>
                </SidebarFooter>
            </ProSidebar>
         );
    }
}
 
export default Sidebar;