import React, { Component } from 'react';
import { NumericFormat } from 'react-number-format';
import colores from '../../../css/bootstrap.scss'
import '../../../css/ingresos.css';
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';
import { MyContext } from '../../../context/AuthContext';
import { MoonLoader } from 'react-spinners';

class Ingresos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            productos: [],
            cuentas: [],
            sucursales: [],
            listaProductos: [],
            registroPrevio: false,
            ingresoRegistro: {
                idProducto: '',
                nombreProducto: '',
                monto: '',
                idSucursal: '',
                nombreSucursal: '',
                descripcion: '',
            },
            idProductoEditar: '',
            idSucursalEditar: '',
            descripcionEditar: '',
            showModal: false,
            showModalEditar: false,
            efectivo: '',
            dataCorte: []
         }
    }

    static contextType = MyContext;
   
    componentDidMount = async () => {
        this.context.cambiarSubruta('Ingresos');
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        await this.selectProductos();
        await this.selectCuentas();
        await this.selectSucursales();
        this.comprobrarRegistros();
        this.selectDatosCorte();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.fecha !== this.props.fecha) {
            if (this.context.rootState.saved) { 
                this.setState({
                    loading: true
                });
                this.componentDidMount();
            }else{
                if (window.confirm('¿Continuar sin guardar cambios?')) {     
                    this.setState({
                        loading: true
                    });   
                    this.componentDidMount();
                }
            }
        }
    }

    alertUser = (e) => {
        if (this.context.rootState.saved === false) {   
            e.preventDefault();
            e.returnValue = '';   
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorSecundario")[0]) {
            document.getElementsByClassName("contenedorSecundario")[0].style.height = (height - 230) + 'px';
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    selectProductos = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/productos.php', requestOptions);
        const data = await response.json();
        data.productos.forEach((_, index) => {
            data.productos[index].monto = '';
        });
        this.setState({
            productos: data.productos
        })
    }
    
    selectCuentas = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/cuentas.php', requestOptions);
        const data = await response.json();
        data.cuentas.forEach((_, index) => {
            data.cuentas[index].monto = '';
        });
        this.setState({
            cuentas: data.cuentas
        })
    }
    
    selectSucursales = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/sucursales.php', requestOptions);
        const data = await response.json();
        data.sucursales.forEach((_, index) => {
            data.sucursales[index].monto = '';
        });
        if (data.sucursales.length === 1) {  
            this.setState({
                sucursales: data.sucursales,
                ingresoRegistro: {
                    idProducto: '',
                    nombreProducto: '',
                    monto: '',
                    idSucursal: 1,
                    nombreSucursal: data.sucursales[0].nombre,
                    descripcion: ''
                }
            })
        }else{
            this.setState({
                sucursales: data.sucursales,
            })
        }
    }

    comprobrarRegistros = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'comprobar', fecha: this.props.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/ingresos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.mensaje !== false) {
                toast.success('Registro encontrado.');
                let cuentas = this.state.cuentas;
                let totalCuentas = 0;
                data.detallesingresos.forEach(element => {
                    let indexCuenta = cuentas.findIndex((e) => e.idCuenta === element.idCuenta);
                    cuentas[indexCuenta].monto = element.monto;
                    totalCuentas = totalCuentas + Number(element.monto)
                });
                let total = 0;
                data.ingresos.forEach(element => {
                    total = total + Number(element.monto);
                });
                this.setState({
                    efectivo: (total - totalCuentas).toFixed(2),
                    cuentas: cuentas,
                    listaProductos: data.ingresos,
                    registroPrevio: true,
                    loading: false
                })
            }else{
                this.setState({
                    registroPrevio: false,
                    listaProductos: [],
                    efectivo: '',
                    loading: false
                })
                this.selectProductos();
                this.selectCuentas();
                this.selectSucursales();
            }
        }else{
            toast.error('Error al consultar registros.');
        }
    }
    
    onChangeMonto = (value) => {
        this.setState({
            ...this.state,
            ingresoRegistro: {
                ...this.state.ingresoRegistro,
                monto: value
            }
        })
    }

    onChangeProducto = (e) => {
        let nombreProducto = '';
        if (e.target.value) {       
            nombreProducto = this.state.productos.find((element) => element.idProducto === e.target.value).nombre;   
        }
        this.setState({
            ...this.state,
            ingresoRegistro: {
                ...this.state.ingresoRegistro,
                idProducto: e.target.value,
                nombreProducto: nombreProducto
            }
        })
    }
    
    onChangeSucursal = (e) => {
        let nombreSucursal = '';
        if (e.target.value) {       
            nombreSucursal = this.state.sucursales.find((element) => element.idSucursal === e.target.value).nombre;   
        }
        this.setState({
            ...this.state,
            ingresoRegistro: {
                ...this.state.ingresoRegistro,
                idSucursal: e.target.value,
                nombreSucursal: nombreSucursal
            }
        })
    }
    
    onChangeDescripcion = (e) => {
        this.setState({
            ...this.state,
            ingresoRegistro: {
                ...this.state.ingresoRegistro,
                descripcion: e.target.value,
            }
        })
    }

    onChangeMontoEfectivo = (value) => {
        this.setState({
            efectivo: value
        })
    }

    onChangeMontoGeneral = (e) => {
        let value = e.target.value.replaceAll('$', '');
        value = value.replaceAll(',', '');
        value = Number(value);
        let cuentas = this.state.cuentas;
        cuentas[e.target.id].monto = value;
        this.setState({
            cuentas: cuentas
        })
    }

    onChangeFecha = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value
        })
        this.comprobrarRegistros();
    }

    agregarIngresoLista = () => {
        toast.remove();
        if (this.state.ingresoRegistro.idProducto && this.state.ingresoRegistro.monto && this.state.ingresoRegistro.idSucursal) {
            if (this.state.listaProductos.find(element => (element.idProducto === this.state.ingresoRegistro.idProducto) && (element.idSucursal === this.state.ingresoRegistro.idSucursal) && (element.descripcion === this.state.ingresoRegistro.descripcion))) {
                let montoPrevio = this.state.listaProductos.find(element => (element.idProducto === this.state.ingresoRegistro.idProducto) && (element.idSucursal === this.state.ingresoRegistro.idSucursal) && (element.descripcion === this.state.ingresoRegistro.descripcion)).monto;
                let listaProductos = this.state.listaProductos;
                let indexProducto = this.state.listaProductos.findIndex(element => (element.idProducto === this.state.ingresoRegistro.idProducto) && (element.idSucursal === this.state.ingresoRegistro.idSucursal) && (element.descripcion === this.state.ingresoRegistro.descripcion));
                listaProductos[indexProducto].monto = Number(montoPrevio) + Number(this.state.ingresoRegistro.monto);
                if (this.state.sucursales.length === 1) {
                    this.setState({
                        listaProductos: listaProductos,
                        ingresoRegistro: {
                            idProducto: '',
                            nombreProducto: '',
                            monto: '',
                            idSucursal: 1,
                            nombreSucursal: this.state.sucursales[0].nombre,
                            descripcion: ''
                        }
                    });
                }else{
                    this.setState({
                        listaProductos: listaProductos,
                        ingresoRegistro: {
                            idProducto: '',
                            nombreProducto: '',
                            monto: '',
                            idSucursal: '',
                            nombreSucursal: '',
                            descripcion: ''
                        }
                    });  
                }
                toast.success('Ingreso sumado en la lista');    
            }else{
                if (this.state.sucursales.length === 1) {
                    this.setState(prevState => ({
                        listaProductos: [...prevState.listaProductos,  this.state.ingresoRegistro],
                        ingresoRegistro: {
                            idProducto: '',
                            nombreProducto: '',
                            monto: '',
                            idSucursal: 1,
                            nombreSucursal: this.state.sucursales[0].nombre,
                            descripcion: ''
                        }
                    }));
                }else{
                    this.setState(prevState => ({
                        listaProductos: [...prevState.listaProductos,  this.state.ingresoRegistro],
                        ingresoRegistro: {
                            idProducto: '',
                            nombreProducto: '',
                            monto: '',
                            idSucursal: '',
                            nombreSucursal: '',
                            descripcion: ''
                        }
                    }));
                }
                toast.success('Ingreso agregado a lista');
            }
            this.context.unsaveChanges();
        } else{
            toast.error('Llene los campos solicitados');
        }
    }

    editarIngresoLista = async () => {
        toast.remove();
        if (this.state.ingresoRegistro.idProducto && this.state.ingresoRegistro.monto && this.state.ingresoRegistro.idSucursal) {            
            let filteredArray = this.state.listaProductos.filter(element => !((element.idProducto === this.state.idProductoEditar) && (element.idSucursal === this.state.idSucursalEditar) && (element.descripcion === this.state.descripcionEditar)));
            await this.setState({
                listaProductos: filteredArray
            });
            this.context.unsaveChanges();
            this.agregarIngresoLista();
            this.hideModalEditar();
        }else{
            toast.error('Llene los campos solicitados');
        }
    }

    quitarIngresoLista = (idProducto, idSucursal, descripcion) => {
        toast.remove();
        if (window.confirm('¿Quitar producto de la lista?')) {
            let filteredArray = this.state.listaProductos.filter(element => !((element.idProducto === idProducto) && (element.idSucursal === idSucursal) && (element.descripcion === descripcion)));
            this.setState({
                listaProductos: filteredArray
            });
            this.context.unsaveChanges();
        }
    }

    getTotal = () => {
        let total = 0;
        let listaProductos = this.state.listaProductos;
        listaProductos.forEach((producto) => {
            total= total += Number(producto.monto)
        });
        total = Number(total).toFixed(2);
        return total
    }
    
    getTotalRestante = () => {
        let total = 0;
        let listaCuentas = this.state.cuentas;
        listaCuentas.forEach((cuenta) => {
            total= total += Number(cuenta.monto)
        });
        total = total + (this.state.efectivo);
        total = Number(total).toFixed(2);
        console.log(total);
        console.log(this.getTotal());
        console.log(this.getTotal() - Number(total))
        return this.getTotal() - Number(total)
    }

    limpiar = () => {
        if (this.state.listaProductos.length !== 0) {
            if (window.confirm('¿Quitar todos los productos de la lista?')) {
                if (this.state.sucursales.length === 1) {                
                    this.setState({
                        listaProductos: [],
                        ingresoRegistro: {
                            idProducto: '',
                            nombreProducto: '',
                            monto: '',
                            idSucursal: 1,
                            nombreSucursal: this.state.sucursales[0].nombre,
                            descripcion: ''
                        }
                    })
                }else{                
                    this.setState({
                        listaProductos: [],
                        ingresoRegistro: {
                            idProducto: '',
                            nombreProducto: '',
                            monto: '',
                            idSucursal: '',
                            nombreSucursal: '',
                            descripcion: ''
                        }
                    })
                }
                this.context.unsaveChanges();
            }
        }
    }

    showModal = async () => {
        let total = this.getTotal();
        if (Number(total) !== 0) {
            this.setState({
                showModal: true
            })
        }else{
            if (this.state.registroPrevio) {
                if (window.confirm('¿Borrar ingresos?')) {
                    this.deleteCorte();
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ operacion: 'delete', fecha: this.props.fecha, empresa: this.context.rootState.nombre })
                    };
                    const response = await fetch('http://regventas.argeos.net/php/DB/ingresos.php', requestOptions);
                    const data = await response.json();
                    if (data.error === 0) {
                        toast.success(data.mensaje);
                        this.reloadHeader();
                        this.setState({
                            registroPrevio: false
                        });
                        this.context.saveChanges();
                    }else{
                        toast.error('Error al borrar ingresos.');
                        console.log(data.mensaje);
                    }
                }
            }else{
                toast.error('No puede registrar un total de $0.');
            }
        }
    }

    hideModal = () => {
        this.setState({
            showModal: false,
        })
    }

    showModalEditar = (idProducto, idSucursal, descripcion) => {
        let ingresoLista = this.state.listaProductos.find(element => (element.idProducto === idProducto) && (element.idSucursal === idSucursal) && (element.descripcion === descripcion));
        this.setState({
            ingresoRegistro: {
                idProducto: ingresoLista.idProducto,
                nombreProducto: ingresoLista.nombreProducto,
                monto: ingresoLista.monto,
                idSucursal: ingresoLista.idSucursal,
                nombreSucursal: ingresoLista.nombreSucursal,
                descripcion: ingresoLista.descripcion
            },
            showModalEditar: true,
            idProductoEditar: idProducto,
            idSucursalEditar: idSucursal,
            descripcionEditar: descripcion
        });
    }

    hideModalEditar = () => {
        if (this.state.sucursales.length === 1) {                
            this.setState({
                ingresoRegistro: {
                    idProducto: '',
                    nombreProducto: '',
                    monto: '',
                    idSucursal: 1,
                    nombreSucursal: this.state.sucursales[0].nombre,
                    descripcion: ''
                },
                showModalEditar: false,
                idProductoEditar: '',
                idSucursalEditar: '',
                descripcionEditar: ''
            })
        }else{                
            this.setState({
                ingresoRegistro: {
                    idProducto: '',
                    nombreProducto: '',
                    monto: '',
                    idSucursal: '',
                    nombreSucursal: '',
                    descripcion: ''
                },
                showModalEditar: false,
                idProductoEditar: '',
                idSucursalEditar: '',
                descripcionEditar: ''
            })
        }
    }

    selectDatosCorte = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectDatosDia', fecha: this.props.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/cortes.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                dataCorte: data
            })
        }else{
            toast.error('Error al obtener datos de corte.');
        }
    }

    registrar = async () => {
        if (this.getTotalRestante() !== 0) {
            toast.error('Los montos y el total no coinciden.');
        }else{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'insert', listaProductos: this.state.listaProductos, fecha: this.props.fecha, cuentas: this.state.cuentas, empresa: this.context.rootState.nombre })
            };
            const response = await fetch('http://regventas.argeos.net/php/DB/ingresos.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success(data.mensaje);
                this.reloadHeader();
                this.setState({
                    registroPrevio: true
                });
                this.context.saveChanges();
                this.hideModal();
            }else{
                toast.error('Error al registrar ingresos.');
                console.log(data.mensaje);
            }
        }
    }

    editar = async () => {
        if (this.getTotalRestante() !== 0) {
            toast.error('Los montos y el total no coinciden.');
        }else{
            this.deleteCorte();
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'update', listaProductos: this.state.listaProductos, fecha: this.props.fecha, cuentas: this.state.cuentas, empresa: this.context.rootState.nombre })
            };
            const response = await fetch('http://regventas.argeos.net/php/DB/ingresos.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success(data.mensaje);
                this.reloadHeader();
                this.setState({
                    registroPrevio: true
                });
                this.context.saveChanges();
                this.hideModal();
            }else{
                toast.error('Error al editar ingresos.');
                console.log(data.mensaje);
            }
        }
    }

    deleteCorte = async () => {
        if (this.props.estatusCorte) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'deleteCorte', fecha: this.props.fecha, cuentas: this.state.dataCorte.cuentas, empresa: this.context.rootState.nombre })
            };
            const response = await fetch('http://regventas.argeos.net/php/DB/cortes.php', requestOptions);
            const data = await response.json();
            if (data.error !== 0) { 
                toast.error('Error al borrar corte previo.');
                console.log(data.mensaje);
            }
        }
    }

    reloadHeader = () => {
        document.getElementById('btnHeaderReload').click();
    }

    render() { 
            return ( 
            <>
                <div className="contenedorSecundario bg-light" style={{ overflowY: 'auto' }}>
                    <div className="row w-100 m-0">
                        <div className="col-12">
                            <div className="elemento py-2">
                                <div className="row">
                                    <div className="col-7">
                                        <label>Producto</label>
                                        <select className='form-control' name="idProducto" value={this.state.ingresoRegistro.idProducto} onChange={this.onChangeProducto}>
                                            <option value="">Seleccionar</option>
                                            {
                                                this.state.productos.map((e, index) =>
                                                    <option key={index} value={e.idProducto}>{e.nombre}</option>
                                                )
                                            }
                                        </select>
                                    </div>                                  
                                    <div className="col-5">
                                        <label>Monto</label>
                                        <div className="input-group">
                                            <span className='input-group-text'>$</span>
                                            <NumericFormat thousandSeparator=',' className='form-control text-end' required value={this.state.ingresoRegistro.monto} defaultValue={''} placeholder='0.0' onValueChange={(values) => this.onChangeMonto(Number(values.value))}/>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label>Descripción</label>
                                        <input type="text" className='form-control' name='descripcion' value={this.state.ingresoRegistro.descripcion} onChange={this.onChangeDescripcion}/>
                                    </div>
                                    <div className="col-7 mt-2">
                                        <label>Sucursal</label>
                                        <select className='form-control' name="idSucursal" value={this.state.ingresoRegistro.idSucursal} onChange={this.onChangeSucursal} disabled={this.state.sucursales.length === 1}>
                                            <option value="">Seleccionar</option>
                                            {
                                                this.state.sucursales.map((e, index) =>
                                                    <option key={index} value={e.idSucursal} hidden={e.cerrado}>{e.nombre}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-5 mt-2 d-flex align-items-end justify-content-center">
                                        <button className='btn btn-info btnRadius px-4' style={{ color: 'white' }} onClick={this.agregarIngresoLista}>Agregar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.listaProductos[0] ?
                                this.state.listaProductos.map((e, index) =>
                                    <div className="col-12 mt-3" key={index}>
                                        <div className="elemento py-1">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-12 d-flex">
                                                            <p className='m-0' style={{ fontSize: '12px' }}>{e.nombreSucursal + ' ->'}</p><p className='m-0 ms-1' style={{ fontSize: '12px' }}>{e.nombreProducto}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className='m-0' style={{ fontSize: '10px' }}>{e.descripcion}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{ borderLeft: '1px solid #e0e0e0' }}>
                                                    <div className="row" style={{ borderBottom: '1px solid #e0e0e0' }}>
                                                        <div className="col-12 text-end">
                                                            <p className='m-0' style={{ fontSize: '14px' }}>$ {this.numberWithCommas(e.monto)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 p-0 text-center">
                                                            <button className='btn btn-sm btn-outline-secondary me-1 ms-auto py-0 px-2 w-75' style={{ height: '20px' }} onClick={() => this.showModalEditar(e.idProducto, e.idSucursal, e.descripcion)}>
                                                                <i className="bi bi-pen" style={{ color: colores.secondary, fontSize: '12px' }}></i>
                                                            </button>
                                                        </div>
                                                        <div className="col-6 p-0 text-center">
                                                            <button className='btn btn-sm btn-outline-danger ms-1 me-auto py-0 px-2 w-75' style={{ height: '20px' }} onClick={() => this.quitarIngresoLista(e.idProducto, e.idSucursal, e.descripcion)}>
                                                                <i className="bi bi-trash" style={{ color: colores.danger, fontSize: '12px' }}></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            :
                                <div className="col-12 mt-3">
                                    <div className="elemento text-center">
                                        <p className='m-0'>Agregue elementos a la lista</p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div className="contenedorSecundarioTotal bg-white" style={{ color: '' }}>
                    <div className="row m-0 h-100">
                        <div className="col-6" style={{ borderRight: '1px solid ' + colores.light, color: '', paddingBlock: '10px' }}>
                            Total Ingresos:
                        </div>
                        <div className="col-6 text-end" style={{ color: '', paddingBlock: '10px' }}>
                            $ {this.numberWithCommas(this.getTotal())}
                        </div>
                        <div className="col-12 text-center d-flex align-items-center py-2 justify-content-center" style={{ borderTop: '1px solid ' + colores.light }}>
                            <button className='btn btn-lg btn-primary btnRadius mx-3' style={{ width: '40%', color: 'white' }} onClick={this.limpiar}>Limpiar</button>
                            <button className='btn btn-lg btn-secondary text-white btnRadius mx-3' style={{ width: '40%' }} onClick={this.showModal}>{this.state.registroPrevio ? 'Editar' : 'Registrar'}</button>
                        </div>
                    </div>
                </div>
                <Toaster/>
                <Modal show={this.state.showModalEditar} onHide={this.hideModalEditar} centered>
                    <Modal.Header className='degradado'>                        
                        <h5 style={{ color: 'white' }} className='m-0'>Editar Producto</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-7">
                                <label>Producto</label>
                                <select className='form-control' name="idProducto" style={{ color: 'black' }} value={this.state.ingresoRegistro.idProducto} onChange={this.onChangeProducto}>
                                    <option value="">Seleccionar</option>
                                    {
                                        this.state.productos.map((e, index) =>
                                            <option key={index} value={e.idProducto}>{e.nombre}</option>
                                        )
                                    }
                                </select>
                            </div>                                  
                            <div className="col-5">
                                <label>Monto</label>
                                <div className="input-group">
                                    <span className='input-group-text'>$</span>
                                    <NumericFormat thousandSeparator=',' className='form-control text-end' style={{ color: 'black' }} required value={this.state.ingresoRegistro.monto} defaultValue={''} placeholder='0.0' onValueChange={(values) => this.onChangeMonto(Number(values.value))}/>
                                </div>
                            </div>
                            <div className="col-12 mt-2">
                                <label>Descripción</label>
                                <input type="text" className='form-control' name='descripcion' style={{ color: 'black' }} value={this.state.ingresoRegistro.descripcion} onChange={this.onChangeDescripcion}/>
                            </div>
                            <div className="col-12 mt-2">
                                <label>Sucursal</label>
                                <select className='form-control' name="idSucursal" style={{ color: 'black' }} value={this.state.ingresoRegistro.idSucursal} onChange={this.onChangeSucursal} disabled={this.state.sucursales.length === 1}>
                                    <option value="">Seleccionar</option>
                                    {
                                        this.state.sucursales.map((e, index) =>
                                            <option key={index} value={e.idSucursal} hidden={e.cerrado}>{e.nombre}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btnRadius btn-primary text-white mx-3' style={{ width: '30%' }} type='button' onClick={this.hideModalEditar}>Cancelar</button>
                        <button className='btn btnRadius btn-secondary mx-3' style={{ width: '30%', color: 'white' }} type='button' onClick={this.editarIngresoLista}>Editar</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showModal} onHide={this.hideModal} centered>
                    <Modal.Header className='degradado'>
                        <h5 style={{ color: 'white' }} className='m-0'>{this.state.registroPrevio ? 'Editar' : 'Registrar'} Ingresos</h5>
                    </Modal.Header>
                    <Modal.Body className='bg-light py-3'>
                        <div className="input-group elemento p-0">
                            <span className='input-group-text w-50'>Efectivo del Día</span>
                            <NumericFormat thousandSeparator=',' prefix='$' value={this.state.efectivo} style={{color: 'black'}} autoComplete="off" className='form-control text-end' defaultValue={''} placeholder='$0.0' onValueChange={(values) => this.onChangeMontoEfectivo(Number(values.value))}/>
                        </div>
                        {
                            this.state.cuentas.map((e, index) =>
                                <div key={index} className="input-group elemento p-0 mt-3" hidden={e.idCuenta === '1'}>
                                    <span className='input-group-text w-50'>{e.nombre}</span>
                                    <NumericFormat thousandSeparator=',' prefix='$' value={e.monto} id={index} style={{ color: 'black' }} autoComplete="off" className='form-control text-end' defaultValue={''} placeholder='$0.0' onChange={this.onChangeMontoGeneral} />
                                </div>
                            )
                        }
                        <div className="row">
                            <div className="col-6">                                
                                <h6 className='text-center mt-3 m-0' style={{ color: this.getTotalRestante() < 0 ? 'red' : 'black' }}>Quedan: $ {this.numberWithCommas(this.getTotalRestante())}</h6>
                            </div>
                            <div className="col-6">
                                <h6 className='text-center mt-3 m-0'>Total: $ {this.numberWithCommas(this.getTotal())}</h6>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='bg-light'>
                        <button className='btn btnRadius btn-primary mx-3 text-white' style={{ width: '30%' }} type='button' onClick={this.hideModal}>Cancelar</button>
                        {
                            this.state.registroPrevio ?
                                <button className='btn btnRadius btn-secondary text-white mx-3' style={{ width: '30%' }} onClick={() => this.editar()}>Editar</button>
                            :
                                <button className='btn btnRadius btn-secondary text-white mx-3' style={{ width: '30%' }} onClick={() => this.registrar()}>Registrar</button>
                        }
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.loading} centered size='sm' className='modalCargando'>
                    <Modal.Body className='ms-auto me-auto'>
                        <MoonLoader color={'#ffffff'} loading={true} size={100} />
                    </Modal.Body>
                </Modal>
            </>
                )
    }
}
 
export default Ingresos;