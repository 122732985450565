import React, { Component } from 'react';
import { NumericFormat } from 'react-number-format';
import colores from '../../../css/bootstrap.scss'
import '../../../css/ingresos.css';
import toast, { Toaster } from 'react-hot-toast';
import { MyContext } from '../../../context/AuthContext';
import { Modal } from 'react-bootstrap';
import { MoonLoader } from 'react-spinners';

class GastosOperativos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            servicios: [],
            cuentas: [],
            sucursales: [],
            listaServicios: [],
            registroPrevio: false,
            costoVentaRegistro: {
                idServicio: '',
                nombreServicio: '',
                idCuenta: '',
                nombreCuenta: '',
                monto: '',
                idSucursal: '',
                nombreSucursal: '',
                descripcion: ''
            },
            idServicioEditar: '',
            idCuentaEditar: '',
            idSucursalEditar: '',
            descripcionEditar: '',
            showModalEditar: false,
            dataCorte: []
         }
    }
    
    static contextType = MyContext;
   
    componentDidMount = async () => {
        this.context.cambiarSubruta('Gastos Operativos');
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        await this.selectServicios();
        await this.selectCuentas();
        await this.selectSucursales();
        this.comprobrarRegistros();
        this.selectDatosCorte();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.fecha !== this.props.fecha) {
            if (this.context.rootState.saved) { 
                this.setState({
                    loading: true
                });
                this.componentDidMount();
            }else{
                if (window.confirm('¿Continuar sin guardar cambios?')) {     
                    this.setState({
                        loading: true
                    });   
                    this.componentDidMount();
                }
            }
        }
    }

    alertUser = (e) => {
        if (this.context.rootState.saved === false) {   
            e.preventDefault();
            e.returnValue = '';   
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorSecundario")[0]) {
            document.getElementsByClassName("contenedorSecundario")[0].style.height = (height - 230) + 'px';
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    selectServicios = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/servicios.php', requestOptions);
        const data = await response.json();
        data.servicios.forEach((_, index) => {
            data.servicios[index].monto = '';
        });
        this.setState({
            servicios: data.servicios
        })
    }
    
    selectCuentas = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/cuentas.php', requestOptions);
        const data = await response.json();
        data.cuentas.forEach((_, index) => {
            data.cuentas[index].monto = '';
        });
        this.setState({
            cuentas: data.cuentas
        })
    }

    selectSucursales = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/sucursales.php', requestOptions);
        const data = await response.json();
        data.sucursales.forEach((_, index) => {
            data.sucursales[index].monto = '';
        });
        if (data.sucursales.length === 1) { 
            this.setState({
                sucursales: data.sucursales,
                costoVentaRegistro: {
                    idServicio: '',
                    nombreServicio: '',
                    idCuenta: '',
                    nombreCuenta: '',
                    monto: '',
                    idSucursal: 1,
                    nombreSucursal: data.sucursales[0].nombre,
                    descripcion: ''
                },
            })
        }else{
            this.setState({
                sucursales: data.sucursales,
            })
        }
    }

    comprobrarRegistros = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'comprobar', fecha: this.props.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/gastosoperativos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.mensaje !== false) {
                toast.success('Registro encontrado.');
                data.listaGastosOperativos.forEach(element => {  
                    if (element.idCuenta !== 'efectivo') {
                        element.nombreCuenta = this.state.cuentas.find(e => e.idCuenta === element.idCuenta).nombre;
                    }else{
                        element.nombreCuenta = 'Efectivo del Día';
                    }
                    element.nombreServicio = this.state.servicios.find(e => e.idServicio === element.idServicio).nombre;
                    element.nombreSucursal = this.state.sucursales.find(e => e.idSucursal === element.idSucursal).nombre;
                });
                this.setState({
                    listaServicios: data.listaGastosOperativos,
                    registroPrevio: true,
                    loading: false
                })
            }else{
                this.setState({
                    registroPrevio: false,
                    listaServicios: [],
                    efectivo: '',
                    loading: false
                })
                this.selectServicios();
                this.selectCuentas();
                this.selectSucursales();
            }
        }else{
            toast.error('Error al consultar registros.');
        }
    }
    
    onChangeMonto = (value) => {
        this.setState({
            ...this.state,
            costoVentaRegistro: {
                ...this.state.costoVentaRegistro,
                monto: value
            }
        })
    }

    onChangeProducto = (e) => {
        let nombreServicio = '';
        if (e.target.value) {       
            nombreServicio = this.state.servicios.find((element) => element.idServicio === e.target.value).nombre;   
        }
        this.setState({
            ...this.state,
            costoVentaRegistro: {
                ...this.state.costoVentaRegistro,
                idServicio: e.target.value,
                nombreServicio: nombreServicio
            }
        })
    }
    
    onChangeCuenta = (e) => {
        let nombreCuenta = '';
        if (e.target.value) {   
            if (e.target.value !== 'efectivo') {
                nombreCuenta = this.state.cuentas.find((element) => element.idCuenta === e.target.value).nombre;  
            }else{
                nombreCuenta = 'Efectivo del Día';
            }
        }
        this.setState({
            ...this.state,
            costoVentaRegistro: {
                ...this.state.costoVentaRegistro,
                idCuenta: e.target.value,
                nombreCuenta: nombreCuenta
            }
        })
    }

    onChangeSucursal = (e) => {
        let nombreSucursal = '';
        if (e.target.value) {       
            nombreSucursal = this.state.sucursales.find((element) => element.idSucursal === e.target.value).nombre;   
        }
        this.setState({
            ...this.state,
            costoVentaRegistro: {
                ...this.state.costoVentaRegistro,
                idSucursal: e.target.value,
                nombreSucursal: nombreSucursal
            }
        })
    }

    onChangeDescripcion = (e) => {
        this.setState({
            ...this.state,
            costoVentaRegistro: {
                ...this.state.costoVentaRegistro,
                descripcion: e.target.value,
            }
        })
    }

    agregarCostoVentaLista = () => {
        toast.remove();
        if (this.state.costoVentaRegistro.idServicio && this.state.costoVentaRegistro.monto && this.state.costoVentaRegistro.idCuenta && this.state.costoVentaRegistro.idSucursal) {
            if (this.state.listaServicios.find(element => (element.idServicio === this.state.costoVentaRegistro.idServicio) && (element.idCuenta === this.state.costoVentaRegistro.idCuenta) && (element.idSucursal === this.state.costoVentaRegistro.idSucursal) && (element.descripcion === this.state.costoVentaRegistro.descripcion))) {
                let montoPrevio = this.state.listaServicios.find(element => (element.idServicio === this.state.costoVentaRegistro.idServicio) && (element.idCuenta === this.state.costoVentaRegistro.idCuenta) && (element.idSucursal === this.state.costoVentaRegistro.idSucursal) && (element.descripcion === this.state.costoVentaRegistro.descripcion)).monto;
                let listaServicios = this.state.listaServicios;
                let indexProducto = this.state.listaServicios.findIndex(element => (element.idServicio === this.state.costoVentaRegistro.idServicio) && (element.idCuenta === this.state.costoVentaRegistro.idCuenta) && (element.idSucursal === this.state.costoVentaRegistro.idSucursal) && (element.descripcion === this.state.costoVentaRegistro.descripcion));
                listaServicios[indexProducto].monto = Number(montoPrevio) + Number(this.state.costoVentaRegistro.monto);
                if (this.state.sucursales.length === 1) {
                    this.setState({
                        listaServicios: listaServicios,
                        costoVentaRegistro: {
                            idServicio: '',
                            nombreServicio: '',
                            idCuenta: '',
                            nombreCuenta: '',
                            monto: '',
                            idSucursal: 1,
                            nombreSucursal: this.state.sucursales[0].nombre,
                            descripcion: ''
                        }
                    });                    
                }else{
                    this.setState({
                        listaServicios: listaServicios,
                        costoVentaRegistro: {
                            idServicio: '',
                            nombreServicio: '',
                            idCuenta: '',
                            nombreCuenta: '',
                            monto: '',
                            idSucursal: '',
                            nombreSucursal: '',
                            descripcion: ''
                        }
                    });
                }
                toast.success('Ingreso sumado en la lista');    
            }else{
                if (this.state.sucursales.length === 1) {                    
                    this.setState(prevState => ({
                        listaServicios: [...prevState.listaServicios,  this.state.costoVentaRegistro],
                        costoVentaRegistro: {
                            idServicio: '',
                            nombreServicio: '',
                            idCuenta: '',
                            nombreCuenta: '',
                            monto: '',
                            idSucursal: 1,
                            nombreSucursal: this.state.sucursales[0].nombre,
                            descripcion: ''
                        }
                    }));
                }else{
                    this.setState(prevState => ({
                        listaServicios: [...prevState.listaServicios,  this.state.costoVentaRegistro],
                        costoVentaRegistro: {
                            idServicio: '',
                            nombreServicio: '',
                            idCuenta: '',
                            nombreCuenta: '',
                            monto: '',
                            idSucursal: '',
                            nombreSucursal: '',
                            descripcion: ''
                        }
                    }));
                }
                toast.success('Gasto operativo agregado a lista');
            }
            this.context.unsaveChanges();
        }else{
            toast.error('Llene los campos solicitados');
        }
    }

    editarCostoVentaLista = async () => {
        toast.remove();
        if (this.state.costoVentaRegistro.idServicio && this.state.costoVentaRegistro.monto && this.state.costoVentaRegistro.idCuenta && this.state.costoVentaRegistro.idSucursal) {
            let filteredArray = this.state.listaServicios.filter(element => !((element.idServicio === this.state.idServicioEditar) && (element.idCuenta === this.state.idCuentaEditar) && (element.idSucursal === this.state.idSucursalEditar) && (element.descripcion === this.state.descripcionEditar)));
            await this.setState({
                listaServicios: filteredArray
            });
            this.context.unsaveChanges();
            this.agregarCostoVentaLista();
            this.hideModalEditar();
        }else{
            toast.error('Llene los campos solicitados');
        }
    }

    quitarCostoVentaLista = (idServicio, idCuenta, idSucursal, descripcion) => {
        toast.remove();
        if (window.confirm('¿Quitar producto de la lista?')) {
            let filteredArray = this.state.listaServicios.filter(element => !((element.idServicio === idServicio) && (element.idCuenta === idCuenta) && (element.idSucursal === idSucursal) && (element.descripcion === descripcion)));
            this.setState({
                listaServicios: filteredArray
            });
            this.context.unsaveChanges();
        }
    }

    getTotal = () => {
        let total = 0;
        let listaServicios = this.state.listaServicios;
        listaServicios.forEach((producto) => {
            total= total += Number(producto.monto)
        });
        return total
    }

    limpiar = () => {
        if (this.state.listaServicios.length !== 0) {
            if (window.confirm('¿Quitar todos los servicios de la lista?')) {
                if (this.state.sucursales.length === 1) {
                    this.setState({
                        listaServicios: [],
                        costoVentaRegistro: {
                            idServicio: '',
                            nombreServicio: '',
                            idCuenta: '',
                            nombreCuenta: '',
                            monto: '',
                            idSucursal: 1,
                            nombreSucursal: this.state.sucursales[0].nombre,
                            descripcion: ''
                        }
                    })                
                }else{                
                    this.setState({
                        listaServicios: [],
                        costoVentaRegistro: {
                            idServicio: '',
                            nombreServicio: '',
                            idCuenta: '',
                            nombreCuenta: '',
                            monto: '',
                            idSucursal: '',
                            nombreSucursal: '',
                            descripcion: ''
                        }
                    }) 
                }
                this.context.unsaveChanges();
            }
        }
    }

    showModalEditar = (idServicio, idCuenta, idSucursal, descripcion) => {
        let ingresoLista = this.state.listaServicios.find(element => (element.idServicio === idServicio) && (element.idCuenta === idCuenta) && (element.idSucursal === idSucursal) && (element.descripcion === descripcion));
        this.setState({
            costoVentaRegistro: {
                idServicio: ingresoLista.idServicio,
                nombreServicio: ingresoLista.nombreServicio,
                idCuenta: ingresoLista.idCuenta,
                nombreCuenta: ingresoLista.nombreCuenta,
                monto: ingresoLista.monto,
                idSucursal: ingresoLista.idSucursal,
                nombreSucursal: ingresoLista.nombreSucursal,
                descripcion: ingresoLista.descripcion
            },
            showModalEditar: true,
            idServicioEditar: idServicio,
            idCuentaEditar: idCuenta,
            idSucursalEditar: idSucursal,
            descripcionEditar: descripcion
        });
    }

    hideModalEditar = () => {
        if (this.state.sucursales.length === 1) {                
            this.setState({
                costoVentaRegistro: {
                    idServicio: '',
                    nombreServicio: '',
                    idCuenta: '',
                    nombreCuenta: '',
                    monto: '',
                    idSucursal: 1,
                    nombreSucursal: this.state.sucursales[0].nombre,
                    descripcion: ''
                },
                showModalEditar: false,
                idServicioEditar: '',
                idCuentaEditar: '',
                idSucursalEditar: '',
                descripcionEditar: ''
            })
        }else{                
            this.setState({
                costoVentaRegistro: {
                    idServicio: '',
                    nombreServicio: '',
                    idCuenta: '',
                    nombreCuenta: '',
                    monto: '',
                    idSucursal: '',
                    nombreSucursal: '',
                    descripcion: ''
                },
                showModalEditar: false,
                idServicioEditar: '',
                idCuentaEditar: '',
                idSucursalEditar: '',
                descripcionEditar: ''
            })
        }
    }

    selectDatosCorte = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectDatosDia', fecha: this.props.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/cortes.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                dataCorte: data
            })
        }else{
            toast.error('Error al obtener datos de corte.');
        }
    }

    registrar = async () => {
        if (this.getTotal() !== 0) {
            this.deleteCorte();
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'insert', listaServicios: this.state.listaServicios, fecha: this.props.fecha, empresa: this.context.rootState.nombre })
            };
            const response = await fetch('http://regventas.argeos.net/php/DB/gastosoperativos.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success(data.mensaje);
                this.reloadHeader();
                this.setState({
                    registroPrevio: true
                });
                this.context.saveChanges();
            }else{
                toast.error('Error al registrar gastos operativos.');
                console.log(data.mensaje);
            }
        }else{
            toast.error('No puede registrar un total de $0.');
        }
    }

    editar = async () => {
        if (this.getTotal() !== 0) {
            this.deleteCorte();
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'update', listaServicios: this.state.listaServicios, fecha: this.props.fecha, empresa: this.context.rootState.nombre })
            };
            const response = await fetch('http://regventas.argeos.net/php/DB/gastosoperativos.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success(data.mensaje);
                this.reloadHeader();
                this.setState({
                    registroPrevio: true
                });
                this.context.saveChanges();
            }else{
                toast.error('Error al actualizar gastos operativos.');
                console.log(data.mensaje);
            }
        }else{
            if (window.confirm('¿Borrar gastos operativos?')) {
                this.deleteCorte();
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'delete', fecha: this.props.fecha, empresa: this.context.rootState.nombre })
                };
                const response = await fetch('http://regventas.argeos.net/php/DB/gastosoperativos.php', requestOptions);
                const data = await response.json();
                if (data.error === 0) {
                    toast.success(data.mensaje);
                    this.reloadHeader();
                    this.setState({
                        registroPrevio: false
                    });
                    this.context.saveChanges();
                }else{
                    toast.error('Error al borrar gastos operativos.');
                    console.log(data.mensaje);
                }
            }
        }
    }

    deleteCorte = async () => {
        if (this.props.estatusCorte) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'deleteCorte', fecha: this.props.fecha, cuentas: this.state.dataCorte.cuentas, empresa: this.context.rootState.nombre })
            };
            const response = await fetch('http://regventas.argeos.net/php/DB/cortes.php', requestOptions);
            const data = await response.json();
            if (data.error !== 0) { 
                toast.error('Error al borrar corte previo.');
                console.log(data.mensaje);
            }
        }
    }

    reloadHeader = () => {
        document.getElementById('btnHeaderReload').click();
    }

    render() { 
            return ( 
            <>
                <div className="contenedorSecundario bg-light" style={{ overflowY: 'auto' }}>
                    <div className="row w-100 m-0">
                        <div className="col-12">
                            <div className="elemento py-2">
                                <div className="row">
                                    <div className="col-7">
                                        <label>Servicios</label>
                                        <select className='form-control' name="idServicio" value={this.state.costoVentaRegistro.idServicio} onChange={this.onChangeProducto}>
                                            <option value="">Seleccionar</option>
                                            {
                                                this.state.servicios.map((e, index) =>
                                                    <option key={index} value={e.idServicio}>{e.nombre}</option>
                                                )
                                            }
                                        </select>
                                    </div>                                  
                                    <div className="col-5">
                                        <label>Monto</label>
                                        <div className="input-group">
                                            <span className='input-group-text'>$</span>
                                            <NumericFormat thousandSeparator=',' className='form-control text-end' required value={this.state.costoVentaRegistro.monto} defaultValue={''} placeholder='0.0' onValueChange={(values) => this.onChangeMonto(Number(values.value))}/>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <label>Pagado con</label>
                                        <select className='form-control' name="idCuenta" value={this.state.costoVentaRegistro.idCuenta} onChange={this.onChangeCuenta}>
                                            <option value="">Seleccionar</option>
                                            <option value="efectivo">Efectivo del Día</option>
                                            {
                                                this.state.cuentas.map((e, index) =>
                                                    <option key={index} value={e.idCuenta}>{e.nombre}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <label>Sucursal</label>
                                        <select className='form-control' name="idSucursal" value={this.state.costoVentaRegistro.idSucursal} onChange={this.onChangeSucursal} disabled={this.state.sucursales.length === 1}>
                                            <option value="">Seleccionar</option>
                                            {
                                                this.state.sucursales.map((e, index) =>
                                                    <option key={index} value={e.idSucursal} hidden={e.cerrado}>{e.nombre}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label>Descripción</label>
                                        <input type="text" className='form-control' name='descripcion' style={{ color: 'black' }} value={this.state.costoVentaRegistro.descripcion} onChange={this.onChangeDescripcion}/>
                                    </div>
                                    <div className="col-12 mt-2 d-flex align-items-end justify-content-center">
                                        <button className='btn btn-info btnRadius px-4' style={{ color: 'white' }} onClick={this.agregarCostoVentaLista}>Agregar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.listaServicios[0] ?
                                this.state.listaServicios.map((e, index) =>
                                    <div className="col-12 mt-3" key={index}>
                                        <div className="elemento py-1">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-12 d-flex">
                                                            <p className='m-0' style={{ fontSize: '12px' }}>{e.nombreSucursal + ' ->'}</p><p className='m-0 ms-1' style={{ fontSize: '12px' }}>{e.nombreServicio}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className='m-0' style={{ fontSize: '10px' }}><i className="bi bi-credit-card"></i> {e.nombreCuenta}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className='m-0' style={{ fontSize: '10px' }}>{e.descripcion}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{ borderLeft: '1px solid #e0e0e0' }}>
                                                    <div className="row" style={{ borderBottom: '1px solid #e0e0e0' }}>
                                                        <div className="col-12 text-end">
                                                            <p className='m-0' style={{ fontSize: '14px' }}>$ {this.numberWithCommas(e.monto)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 p-0 text-center">
                                                            <button className='btn btn-sm btn-outline-secondary me-1 ms-auto py-0 px-2 w-75' style={{ height: '20px' }} onClick={() => this.showModalEditar(e.idServicio, e.idCuenta, e.idSucursal, e.descripcion)}>
                                                                <i className="bi bi-pen" style={{ color: colores.secondary, fontSize: '12px' }}></i>
                                                            </button>
                                                        </div>
                                                        <div className="col-6 p-0 text-center">
                                                            <button className='btn btn-sm btn-outline-danger ms-1 me-auto py-0 px-2 w-75' style={{ height: '20px' }} onClick={() => this.quitarCostoVentaLista(e.idServicio, e.idCuenta, e.idSucursal, e.descripcion)}>
                                                                <i className="bi bi-trash" style={{ color: colores.danger, fontSize: '12px' }}></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            :
                                <div className="col-12 mt-3">
                                    <div className="elemento text-center">
                                        <p className='m-0'>Agregue elementos a la lista</p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div className="contenedorSecundarioTotal bg-white">
                    <div className="row m-0 h-100">
                        <div className="col-6" style={{ borderRight: '1px solid ' + colores.light, color: 'black', paddingBlock: '10px' }}>
                            Total Gastos Operativos:
                        </div>
                        <div className="col-6 text-end" style={{ color: 'black', paddingBlock: '10px' }}>
                            $ {this.numberWithCommas(this.getTotal())}
                        </div>
                        <div className="col-12 text-center d-flex align-items-center py-2 justify-content-center" style={{ borderTop: '1px solid ' + colores.light }}>
                            <button className='btn btn-lg btn-primary btnRadius mx-3' style={{ width: '40%', color: 'white' }} onClick={this.limpiar}>Limpiar</button>
                            {
                                this.state.registroPrevio ?
                                    <button className='btn btn-lg btn-secondary text-white btnRadius mx-3' style={{ width: '40%' }} onClick={this.editar}>Editar</button>
                                :
                                    <button className='btn btn-lg btn-secondary text-white btnRadius mx-3' style={{ width: '40%' }} onClick={this.registrar}>Registrar</button>
                            }
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModalEditar} onHide={this.hideModalEditar} centered>
                    <Modal.Header className='degradado'>                        
                        <h5 style={{ color: 'white' }} className='m-0'>Editar Servicio</h5>
                    </Modal.Header>
                    <Modal.Body>                        
                        <div className="row">
                            <div className="col-7">
                                <label>Servicios</label>
                                <select className='form-control' name="idServicio" style={{ color: 'black' }} value={this.state.costoVentaRegistro.idServicio} onChange={this.onChangeProducto}>
                                    <option value="">Seleccionar</option>
                                    {
                                        this.state.servicios.map((e, index) =>
                                            <option key={index} value={e.idServicio}>{e.nombre}</option>
                                        )
                                    }
                                </select>
                            </div>                                  
                            <div className="col-5">
                                <label>Monto</label>
                                <div className="input-group">
                                    <span className='input-group-text'>$</span>
                                    <NumericFormat thousandSeparator=',' className='form-control text-end' style={{ color: 'black' }} required value={this.state.costoVentaRegistro.monto} defaultValue={''} placeholder='0.0' onValueChange={(values) => this.onChangeMonto(Number(values.value))}/>
                                </div>
                            </div>
                            <div className="col-6 mt-2">
                                <label>Pagado con</label>
                                <select className='form-control' name="idCuenta" style={{ color: 'black' }} value={this.state.costoVentaRegistro.idCuenta} onChange={this.onChangeCuenta}>
                                    <option value="">Seleccionar</option>
                                    <option value="efectivo">Efectivo del Día</option>
                                    {
                                        this.state.cuentas.map((e, index) =>
                                            <option key={index} value={e.idCuenta}>{e.nombre}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col-6 mt-2">
                                <label>Sucursal</label>
                                <select className='form-control' name="idSucursal" style={{ color: 'black' }} value={this.state.costoVentaRegistro.idSucursal} onChange={this.onChangeSucursal} disabled={this.state.sucursales.length === 1}>
                                    <option value="">Seleccionar</option>
                                    {
                                        this.state.sucursales.map((e, index) =>
                                            <option key={index} value={e.idSucursal} hidden={e.cerrado}>{e.nombre}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col-12 mt-2">
                                <label>Descripción</label>
                                <input type="text" className='form-control' name='descripcion' style={{ color: 'black' }} value={this.state.costoVentaRegistro.descripcion} onChange={this.onChangeDescripcion}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btnRadius btn-primary text-white mx-3' style={{ width: '30%' }} type='button' onClick={this.hideModalEditar}>Cancelar</button>
                        <button className='btn btnRadius btn-secondary mx-3' style={{ width: '30%', color: 'white' }} type='button' onClick={this.editarCostoVentaLista}>Editar</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.loading} centered size='sm' className='modalCargando'>
                    <Modal.Body className='ms-auto me-auto'>
                        <MoonLoader color={'#ffffff'} loading={true} size={100} />
                    </Modal.Body>
                </Modal>
                <Toaster/>
            </>
                )
    }
}
 
export default GastosOperativos;