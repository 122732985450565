import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const app = firebase.initializeApp({
    apiKey: "AIzaSyC8BRYSYtIhErh6R_Wd08P5RxkBHQGeeSQ",
    authDomain: "regventas-ecf7a.firebaseapp.com",
    projectId: "regventas-ecf7a",
    storageBucket: "regventas-ecf7a.appspot.com",
    messagingSenderId: "866976314238",
    appId: "1:866976314238:web:0d809600389eaba885792f"
});

export const auth = firebase.auth();
export default app;