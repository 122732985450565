import React from 'react';
import colores from  '../css/bootstrap.scss';

class NuevaCuenta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    render() { 
        return ( 
            <>
                <div className="container-fluid bg-light d-flex" style={{ minHeight: '100vh', height: 'fit-content' }}>
                    <div className='mx-auto px-4' style={{ width: '100%', maxWidth: '500px' }}>                        
                        <div className="row">
                            <div className="col-12 mt-4">                            
                                <div className="elemento p-0 w-100" style={{ width: '100%' }}>
                                    <div className="row m-0">
                                        <div className={"col-2 d-flex justify-content-center " + (this.state.ruta === 'Registrar' ? 'd-none' : 'd-flex')}>
                                            <a href='/login' className='btn' style={{ width: 'fit-content', height: '50px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill={colores.secondary} className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                                                </svg>
                                            </a>
                                        </div>
                                        <div className="col-10 d-flex align-items-center bg-dark pe-0" style={{ height: '50px', color: 'white', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', borderBottomLeftRadius: this.state.ruta !== 'Registrar' ? '0px' : '5px', borderTopLeftRadius: this.state.ruta !== 'Registrar' ? '0px' : '5px' }}>
                                            <h6 className='m-0'>Volver a inicio de sesión</h6>                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">               
                            <div className="col-12 mt-2">
                                <div className="elemento p-0">
                                    <div className="row m-0">
                                        <div className="col-12 d-flex align-items-center bg-secondary text-white pe-0 justify-content-center" style={{ height: '40px', borderRadius: '5px' }}>
                                            <h6 className='m-0'>Video demostración</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-2">
                                    <video width="auto" height="500" controls style={{ borderRadius: '5px', boxShadow: 'rgba(255, 255, 255, 0.2) 0px 8px 24px', maxWidth: '100%' }}>
                                        <source src="/video/Demostracion.mp4" type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                            </div>
                            <div className="col-12 my-3">
                                <div className="elemento bg-white p-0 px-2 py-1">
                                    <p className='m-0' style={{ fontSize: '14px' }}>Si te interesa, manda un correo a <a style={{ fontSize: '14px' }} href="mailto: argeos.serviciosyproductos@gmail.com?Subject=Interesado%20en%20el%sistema">argeos.serviciosyproductos@gmail.com</a> y nos pondremos en contacto contigo lo antes posible para asignarte una cuenta.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
         );
    }
}
 
export default NuevaCuenta;