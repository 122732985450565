import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { MyContext } from '../../context/AuthContext';
import { Modal } from 'react-bootstrap';
import { auth } from '../../firebase';

class Usuario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalContrasena: false
         }
    }

    static contextType = MyContext;
    
    componentDidMount = async () => {
        await this.context.cambiarRuta('Configuración');
        this.context.cambiarSubruta('Usuario');
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPrincipal")[0]) {
                document.getElementsByClassName("contenedorPrincipal")[0].style.height = (height - 50) + 'px';
        }
    }

    showModalContrasena = () => {
        this.setState({
            showModalContrasena: true
        })
    }
    
    hideModalContrasena = () => {
        this.setState({
            showModalContrasena: false
        })
    }

    cambiarContrasena = async () => {
        try {
            await auth.sendPasswordResetEmail(this.context.rootState.email, null);
            toast.success('Correo para cambiar contraseña enviado, si no lo encuenta, revise su bandeja de spam.', { duration: 5000 });
            this.hideModalContrasena();
        }catch (error) {
            switch (error.code) {
                case 'auth/internal-error':
                    toast.error('Error interno');
                    break;
                default:
                    toast.error(error.code);
                    break;
            }
        }
    }
    
    render() { 
        return ( 
            <>
            <div className="contenedorPrincipal p-0 bg-light">
                <div className='mx-auto' style={{ maxWidth: '500px' }}>
                    <div className="row w-100 m-0">
                        <div className="col-12 px-3 my-3">
                            <div className="elemento">
                                <div className="row">
                                    <div className="col-12">
                                        <label>Email</label>
                                        <input type="text" className='form-control' name='email' value={this.context.rootState.email} onChange={this.onChangeValue} readOnly/>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <button className='btn btnRadius btn-secondary d-flex mx-auto px-4' onClick={this.showModalContrasena}>Cambiar Contraseña</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster/>
            <Modal show={this.state.showModalContrasena} onHide={this.hideModalContrasena} centered>
                <Modal.Header className='degradado text-white'>
                    <h1 className='m-0' style={{ fontSize: '22px' }}>Cambio de contraseña</h1>
                </Modal.Header>
                <Modal.Body>
                    <p className='m-0'>Se enviará un enlace a {this.context.rootState.email}, en el cual podrá cambiar su contraseña.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btnRadius btn-primary text-white mx-3' style={{ width: '30%' }} type='button' onClick={this.hideModalContrasena}>Cancelar</button>
                    <button className='btn btnRadius btn-secondary mx-3' style={{ width: '30%', color: 'white' }} type='button' onClick={this.cambiarContrasena}>Enviar</button>
                </Modal.Footer>
            </Modal>
            </>
         );
    }
}
 
export default Usuario;