import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom'; 
import {MyContext} from '../context/AuthContext';
import Cargando from './Cargando';
 
export const LoginRoute = ({ component: Component, ...rest}) => {
    const contexto = useContext(MyContext);

    return (
        <Route
        {...rest}
        render={() => {
            return (contexto.rootState.currentUser === '') ? 
                    <Cargando/>
                :
                    (contexto.rootState.currentUser === null) ?
                        <Component/>
                    :
                        <Redirect to='/'/>
        }}
        >
        </Route>
    )
}
