import React, { Component } from 'react';
import colores from '../css/bootstrap.scss';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { MyContext } from '../context/AuthContext';
import { auth } from '../firebase';

class Administracion extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            usuarios: [],
            showModal: false,
            emailUsuario: '',
        }
    }

    static contextType = MyContext;

    componentDidMount = async () => {
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
        this.select();
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPrincipal")[0]) {
            document.getElementsByClassName("contenedorPrincipal")[0].style.height = (height - 110) + 'px';
        }
    }

    select = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/administracion.php', requestOptions);
        const data = await response.json();
        this.setState({
            usuarios: data
        })
    }

    showModal = () => {
        this.setState({
            showModal: true
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false,
            emailUsuario: ''
        })
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    borrar = async (id, email) => {
        if (window.confirm("Eliminar el usuario borrará toda la información relacionada al mismo, ¿Continuar?")) {            
            const requestOptionsFBD = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email })
            };
            const responseFBD = await fetch('https://us-central1-regventas-ecf7a.cloudfunctions.net/app/api/deleteUser', requestOptionsFBD);
            const dataFBD = await responseFBD.json();
            if (dataFBD.code !== "") {
                toast.error(dataFBD.code);
            }
            const requestOptions = {
                method: 'POST',
                header: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'delete', idUsuario: id })
            };
            const response = await fetch('http://regventas.argeos.net/php/DB/administracion.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success(data.mensaje);
                this.select();
            }else{
                toast.error('Hubo un error al realizar la petición.');
                console.log(data.mensaje);
            }
        }
    }

    agregar = async (e) => {
        e.preventDefault();
        if (this.state.emailUsuario !== '') {
            var pass = '';
            var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$';        
            for (var i = 1; i <= 8; i++) {
                var char = Math.floor(Math.random() * str.length + 1);    
                pass += str.charAt(char)
            }
            console.log(pass);
            const requestOptionsFB = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: this.state.emailUsuario, password: pass })
            };
            const responseFB = await fetch('https://us-central1-regventas-ecf7a.cloudfunctions.net/app/api/insertUser', requestOptionsFB);
            const dataFB = await responseFB.json();
            if (dataFB.code !== "") {
                switch (dataFB.code) {
                    case 'auth/internal-error':
                        toast.error('Error interno.');
                        break;
                    case 'auth/email-already-exists':
                        toast.error('Este correo electrónico ya está registrado.');
                        break;
                    default:
                        toast.error(dataFB.code);
                        break;
                }
            }else{
                const requestOptionsIns = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        operacion: 'insert',
                        email: this.state.emailUsuario
                    })
                };
                const responseIns = await fetch('http://regventas.argeos.net/php/DB/administracion.php', requestOptionsIns);
                const dataIns = await responseIns.json();
                if (dataIns.error !== 0) {
                    if (dataIns.mensaje.errorInfo[1] === 1062) {
                        toast.error('Usuario existe en BD, pero no en FB.');
                    }else{
                        toast.error('Error al agregar usuario.');
                        console.log(dataIns.mensaje);
                    }
                    const requestOptionsFBD = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ email: this.state.emailUsuario })
                    };
                    const responseFBD = await fetch('https://us-central1-regventas-ecf7a.cloudfunctions.net/app/api/deleteUser', requestOptionsFBD);
                    const dataFBD = await responseFBD.json();
                    console.log(dataFBD);
                }else{
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                        operacion: 'enviarCorreo',
                        correo: this.state.emailUsuario,
                        contrasena: pass,
                        })
                    };
                    const response = await fetch('http://regventas.argeos.net/php/avisocliente.php', requestOptions);
                    const data = await response.json();
                    if (data.error !== 0) {
                        toast('Cuenta creada. ' + data.mensaje, { icon: <i style={{ color: '#ffe900' }} className="bi bi-exclamation-lg"></i> })
                        this.hideModal();
                        this.select();
                    }else{
                        toast.success('Cuenta creada.');
                        this.hideModal();
                        this.select();
                    }
                }
            }
        }else{
            toast.error('El email del usuario está vacío.');
        }        
    }

    cambiarContrasena = async () => {
        try {
            await auth.sendPasswordResetEmail(this.context.rootState.email, null);
            toast.success('Correo para cambiar contraseña enviado, si no lo encuenta, revise su bandeja de spam.', { duration: 5000 });
        }catch (error) {
            switch (error.code) {
                case 'auth/internal-error':
                    toast.error('Error interno');
                    break;
                default:
                    toast.error(error.code);
                    break;
            }
        }
    }
    
    render() { 
        return ( 
            <>
            <div className="contenedorPrincipal bg-light">
                <div className="row w-100 pt-3 m-0">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 mt-1">
                                <button className='btn btnRadius btn-secondary d-flex mx-auto px-4' style={{ color: 'white' }} onClick={() => this.cambiarContrasena()}>Cambiar Contraseña</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="elemento p-0">
                            <div className="row m-0">
                                <div className={"col-12 d-flex align-items-center bg-secondary pe-0"} style={{ height: '50px', color: 'white', borderRadius: '5px' }}>
                                    <h6 className='m-0'>Usuarios</h6>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className="col-12 d-flex">
                        <button className='btnAgregar mx-auto' onClick={this.showModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill={colores.secondary} className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                            </svg>
                        </button>
                    </div>
                    <div className="col-12 mt-3">
                        {
                            this.state.usuarios[0] ?
                                this.state.usuarios.map((e, index) =>
                                    <div className="elemento mt-3 align-items-center" key={index}>
                                        <div className="d-flex">
                                            <p className='m-0'>{e.email}</p>
                                            <button className='btn btn-outline-danger btnRadius ms-auto' onClick={() => this.borrar(e.idUsuario, e.email)} disabled={e.idUsuario === '1'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={colores.danger} className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                )
                            :
                            <div className="elemento">
                                <p className='text-center m-0'>No hay usuarios registrados</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal show={this.state.showModal} onHide={this.hideModal} centered>    
            <form action="" onSubmit={this.agregar}>
                <Modal.Header className='degradado'>
                    <h5 style={{ color: 'white' }} className='m-0'>Agregar Usuarios</h5>
                </Modal.Header>
                <Modal.Body>
                        <label htmlFor="emailUsuario">Email del Usuario</label>
                        <input className='form-control mt-1' type="email" name="emailUsuario" id="emailUsuario" value={this.state.emailUsuario} onChange={this.onChangeValue}/>
                        <p className='text-center m-0 mt-2' style={{ fontSize: '12px' }}>La contraseña será 123123123 y podrá cambiarla iniciando sesión.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btnRadius btn-primary text-white mx-3' style={{ width: '30%' }} type='button' onClick={this.hideModal}>Cancelar</button>
                    <button className='btn btnRadius btn-secondary mx-3' style={{ width: '30%' }} type='subtmit'>Agregar</button>
                </Modal.Footer>
            </form>
            </Modal>
            <Toaster/>
            </>
         );
    }
}
 
export default Administracion;