import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { LoginRoute } from './LoginRoute';
import Login from './Login';
import Inicio from './Inicio';
import Registrar from './Registrar/Registrar';
import Registros from './Registros/Registros';
import Productos from './Configuracion/Productos/Productos';
import Servicios from './Configuracion/Servicios/Servicios';
import Logo from './Configuracion/Logo';
import Ingresos from './Registrar/Ingresos/Ingresos';
import Cuentas from './Configuracion/Cuentas/Cuentas';
import Sucursales from './Configuracion/Sucursales/Sucursales';
import Usuario from './Configuracion/Usuario';
import TiposRepartoUtilidades from './Configuracion/TiposRepartoUtilidades/TiposRepartoUtilidades';
import TiposInversion from './Configuracion/TiposInversion/TiposInversion';
import NuevaCuenta from './NuevaCuenta';
import TiposCostoVenta from './Configuracion/TiposCostoVenta/TiposCostoVenta';
import RestablecerContrasena from './RestablecerContrasena';

function Routes () {

    return (
        <Switch>
          <LoginRoute path="/login" component={Login}/>
          <LoginRoute path="/restablecercontrasena" component={RestablecerContrasena}/>
          <LoginRoute path="/nuevacuenta" component={NuevaCuenta}/>
          <PrivateRoute exact path="/" component={Inicio}/>
          <PrivateRoute exact path="/registrar" component={Registrar}/>
          <PrivateRoute exact path="/registros" component={Registros}/>
          <PrivateRoute exact path="/configuracion/usuario" component={Usuario}/>
          <PrivateRoute exact path="/configuracion/sucursales" component={Sucursales}/>
          <PrivateRoute exact path="/configuracion/productos" component={Productos}/>
          <PrivateRoute exact path="/configuracion/tiposcostoventa" component={TiposCostoVenta}/>
          <PrivateRoute exact path="/configuracion/servicios" component={Servicios}/>
          <PrivateRoute exact path="/configuracion/tiposrepartoutilidades" component={TiposRepartoUtilidades}/>
          <PrivateRoute exact path="/configuracion/tiposinversion" component={TiposInversion}/>
          <PrivateRoute exact path="/configuracion/cuentas" component={Cuentas}/>
          <PrivateRoute exact path="/configuracion/logo" component={Logo}/>
          <PrivateRoute exact path="/registrar/ingresos" component={Ingresos}/>
          <Route exact path="*" component={() => <Redirect to={'/'}/>}/>
        </Switch>
      )
}

export default Routes;