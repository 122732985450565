import React, { Component } from 'react';
import '../../css/registros.css';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import toast from 'react-hot-toast';
import Anual from './General/Anual';
import CompararAnual from './General/CompararAnual';
import Rango from './General/Rango';
import Mensual from './General/Mensual';
import Diario from './General/Diario';
import Calendario from './General/Calendario/Calendario';
import Detallado from './General/Detallado';
import CorteDiario from './General/CorteDiario';
import SaldoDiario from './General/SaldoDiario';
import AnualGO from './GastosOperativos/AnualGO';
import RangoGO from './GastosOperativos/RangoGO';
import { MyContext } from '../../context/AuthContext';
import Proyecto from './General/Proyecto';

class Registros extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verPor: 'diario',
            verPorSucursal: '',
            categoria: 'general',
            sucursales: [],
            sucursalPresupuesto: false
         }
    }

    static contextType = MyContext;

    componentDidMount = async () => {
        this.context.cambiarRuta('Reportes');
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
        this.selectSucursales();
    }

    selectSucursales = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/sucursales.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                sucursales: data.sucursales
            })
        }else{
            toast.error('Error al cargar sucursales');
            console.log(data.mensaje);
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPrincipal")[0]) {
                document.getElementsByClassName("contenedorPrincipal")[0].style.height = (height - 50) + 'px';
        }
    }

    onChangeCategoria = (e) => {
        toast.remove();
        if (e.target.value === 'general') {            
            this.setState({
                categoria: e.target.value,
                verPorSucursal: '',
                verPor: 'diario'
            })
        }else{
            if (e.target.value === 'porSucursal') {
                this.setState({
                    categoria: e.target.value,
                    verPorSucursal: this.state.sucursales[0].idSucursal,
                    verPor: 'diario',
                })
            }else{
                this.setState({
                    categoria: e.target.value,
                    verPorSucursal: '',
                    verPor: 'anualGO'
                })
            }
        }
    }

    onChangeVerPor = (e) => {
        toast.remove();
        this.setState({
            verPor: e.target.value
        })
    }

    onChangeVerPorSucursal = (e) => {
        toast.remove();
        this.setState({
            verPorSucursal: e.target.value
        })
    }

    hideProyecto = () => {
        let hide = true;
        if (this.state.verPorSucursal !== '') {
            if (Number(this.state.sucursales.find(eSucursal => eSucursal.idSucursal === this.state.verPorSucursal).presupuesto) === 0) {
                hide = true;
            }else{
                hide = false;
            }
        }else{
            hide = true;
        }
        return hide
    }
    
    render() { 
        return ( 
            <>
            <div className="contenedorPrincipal p-0 bg-light registros">
                <div className='mx-auto' style={{ maxWidth: '500px' }}>
                    <div className="row w-100 m-0">
                        <div className="row m-0 p-0 px-3 pt-2">
                            <div className="col-12">
                                <div className="row pb-0 pt-1 px-0 d-flex justify-content-center" style={{ borderBottomLeftRadius: this.state.verPor === 'proyecto' ? '' : '0px', borderBottomRightRadius: this.state.verPor === 'proyecto' ? '' : '0px' }}>
                                    <div className={(this.state.categoria === 'porSucursal' ? "col-4 px-1" : 'col-4 px-1') + " py-0"}>
                                        <div className="inputGroup">
                                            <label htmlFor="categoria">Vista</label>
                                            <select name="categoria" id="categoria" className='form-control inputUnderline text-white' value={this.state.categoria} style={{ borderRadius: '0px', fontSize: '16px' }} onChange={this.onChangeCategoria}>
                                                <option value="general">General</option>
                                                <option value="porSucursal" hidden={this.state.sucursales.length === 1}>Por Sucursal</option>
                                                <option value="gastosoperativos">Gastos Operativos</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={(this.state.categoria === 'porSucursal' ? "col-4 px-1" : 'col-4 px-1') + " py-0"} hidden={this.state.categoria !== 'porSucursal'}>
                                        <div className="inputGroup">
                                            <label htmlFor="verPorSucursal">Sucursal</label>
                                            <select name="verPorSucursal" id="verPorSucursal" className='form-control inputUnderline text-white' value={this.state.verPorSucursal} style={{ borderRadius: '0px', fontSize: '16px' }} onChange={this.onChangeVerPorSucursal}>
                                                {
                                                    this.state.sucursales.map((e, index) =>
                                                        <option key={index} value={e.idSucursal}>{e.nombre}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className={(this.state.categoria === 'porSucursal' ? "col-4 px-1" : "col-4 px-1") + " py-0 " + (this.state.verPor === 'proyecto' ? 'pb-3' : '')}>
                                        <div className="inputGroup">
                                            <label htmlFor="verPor">Tipo Reporte</label>
                                            <select name="verPor" id="verPor" className='form-control inputUnderline text-white' value={this.state.verPor} style={{ borderRadius: '0px', fontSize: '16px' }} onChange={this.onChangeVerPor}>
                                                <option value="diario" hidden={this.state.categoria !== 'general' && this.state.categoria !== 'porSucursal'}>Diario</option>
                                                <option value="corteDiario" hidden={this.state.categoria !== 'general'}>Corte Diario</option>
                                                <option value="saldoDiario" hidden={this.state.categoria !== 'general'}>Saldo Diario</option>
                                                <option value="mensual" hidden={this.state.categoria !== 'general' && this.state.categoria !== 'porSucursal'}>Mensual</option>
                                                <option value="rango" hidden={this.state.categoria !== 'general' && this.state.categoria !== 'porSucursal'}>Rango</option>
                                                <option value="calendario" hidden={this.state.categoria !== 'general' && this.state.categoria !== 'porSucursal'}>Calendario</option>
                                                <option value="detallado" hidden={this.state.categoria !== 'general' && this.state.categoria !== 'porSucursal'}>Detallado</option>
                                                <option value="anual" hidden={this.state.categoria !== 'general' && this.state.categoria !== 'porSucursal'}>Anual</option>
                                                <option value="compararAnual" hidden={this.state.categoria !== 'general' && this.state.categoria !== 'porSucursal'}>Comparar Años</option>
                                                <option value="anualGO" hidden={this.state.categoria !== 'gastosoperativos'}>Anual</option>
                                                <option value="rangoGO" hidden={this.state.categoria !== 'gastosoperativos'}>Rango</option>
                                                <option value="proyecto" hidden={this.hideProyecto()}>Proyecto</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        this.state.verPor === 'diario' &&
                                            <Diario sucursal={this.state.verPorSucursal}/>
                                    }
                                    {
                                        this.state.verPor === 'corteDiario' &&
                                            <CorteDiario/>
                                    }
                                    {
                                        this.state.verPor === 'saldoDiario' &&
                                            <SaldoDiario/>
                                    }
                                    {
                                        this.state.verPor === 'mensual' &&
                                            <Mensual sucursal={this.state.verPorSucursal}/>
                                    }
                                    {
                                        this.state.verPor === 'rango' &&
                                            <Rango sucursal={this.state.verPorSucursal}/>
                                    }
                                    {
                                        this.state.verPor === 'calendario' &&
                                            <Calendario sucursal={this.state.verPorSucursal}/>
                                    }
                                    {
                                        this.state.verPor === 'detallado' &&
                                            <Detallado sucursal={this.state.verPorSucursal}/>
                                    }
                                    {
                                        this.state.verPor === 'anual' &&
                                            <Anual sucursal={this.state.verPorSucursal}/>
                                    }
                                    {
                                        this.state.verPor === 'compararAnual' &&
                                            <CompararAnual sucursal={this.state.verPorSucursal}/>
                                    }
                                    {
                                        this.state.verPor === 'anualGO' &&
                                            <AnualGO/>
                                    }
                                    {
                                        this.state.verPor === 'rangoGO' &&
                                            <RangoGO/>
                                    }
                                    {
                                        this.state.verPor === 'proyecto' &&
                                            <Proyecto sucursal={this.state.verPorSucursal}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
         );
    }
}
 
export default Registros;