import React, { useState, useContext } from 'react';
import { MyContext } from '../context/AuthContext';
 
function Login () {
    const contexto = useContext(MyContext);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePasssword = (e) => setPassword(e.target.value);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
        await contexto.login(email, password);
    }catch (error){
        switch (error.code) {
            case 'auth/user-not-found':
                setError('No existe una cuenta asociada a este correo');
                break;
            case 'auth/wrong-password':
                setError('Contraseña incorrecta');
                break;
            case 'auth/too-many-requests':
                setError('Demasiados intentos, corrobore su contraseña');
                break;
            default:
                setError('Error interno: (' + error.code + ')');
                console.log(error.code);
                break;
        }
        setTimeout(() => setError(''), 5000);
    }
  }

  return (
    <>
    <div className="container-fluid d-flex align-items-center fondoLogin" style={{ height: '100vh' }}>
        <div className='px-4 mx-auto' style={{ maxWidth: '500px', width: '100%' }}>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card text-white" style={{ backgroundColor: 'rgba(0,0,0,0.15)', border: 'none', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                        <div className="card-header text-center py-3">
                            <h1 className='m-0'>Iniciar Sesión</h1>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-floating">
                                    <input type="email" name='email' id='email' className="form-control inputUnderline text-white" style={{ borderBottom: '2px solid white', borderRadius: '0px' }} placeholder="Email" required onChange={handleEmail}/>
                                    <label htmlFor="email">Correo electrónico</label>
                                </div>
                                <div className="form-floating mt-4">
                                    <input type="password" name='password' id='password' className="form-control inputUnderline text-white" style={{ borderBottom: '2px solid white', borderRadius: '0px' }} placeholder="Contrasena" required onChange={handlePasssword}/>
                                    <label htmlFor="password">Contraseña</label>
                                </div>
                                <div className='w-100 text-end mt-1'>
                                    <a href="/restablecercontrasena" style={{ color: 'white', fontSize: '12px' }}>¿Has olvidado tu contraseña?</a>
                                </div>
                                <button type="submit" className="btnRadius boxShadow px-4 d-flex mx-auto mt-3 btn btn-outline-light" style={{ width: 'fit-content', fontSize: '18px' }}>Ingresar</button>
                            </form>
                        </div>
                        <div className="card-footer text-center" style={{ borderColor: 'rgba(255,255,255,0.2)' }}>
                            <div className="row">
                                <div className="col-12">                                    
                                    {error && <p className='error m-0' style={{ color: '#ffa5a5' }}>{error}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 d-flex justify-content-center">
                    <a href="/nuevacuenta" style={{ color: 'white' }}>¿Aún no tienes cuenta?</a>
                </div>
            </div>
        </div>
    </div>
    </>
  )
 
}
 
export default Login