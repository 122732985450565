import React, { Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { MyContext } from '../../context/AuthContext';

class Logo extends Component {
    constructor(props) {
        super(props);
        this.logo = React.createRef();
        this.state = {
            hideInput: true,
            urlImg: ''
         }
    }
    
    static contextType = MyContext;

    componentDidMount = async () => {
        await this.context.cambiarRuta('Configuración');
        this.context.cambiarSubruta('Logo');
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPrincipal")[0]) {
                document.getElementsByClassName("contenedorPrincipal")[0].style.height = (height - 50) + 'px';
        }
    }

    showInput = () => {
        this.setState({
            hideInput: false
        })
    }

    hideInput = () => {
        this.setState({
            hideInput: true
        })
    }

    cambiarLogo = async () => {
        if (this.logo.current.files.length > 0) {  
            var formData = new FormData ();
            formData.append('logo', this.logo.current.files[0]);
            formData.append('nombreImg', this.context.rootState.nombre);
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const response = await fetch('http://regventas.argeos.net/php/DB/logo.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                toast.success(data.mensaje);
            }else{
                toast.error(data.mensaje);
            }
        }else{
            toast.error('No hay una imagen seleccionada.');
        }
    }

    onChangeImg = () => {
        if (this.logo.current.files.length > 0) {
            this.setState({
                urlImg: URL.createObjectURL(this.logo.current.files[0])
            })
        }else{
            this.setState({
                urlImg: ''
            })
        }
    }
    
    render() { 
        return ( 
            <>
            <div className="contenedorPrincipal p-0 bg-light">
                <div className='mx-auto' style={{ maxWidth: '500px' }}>
                    <div className="row w-100 m-0">
                        <div className="col-12 p-0">
                        </div>
                        <div className="col-12 mt-3 text-center">
                            <div className="elemento bg-dark">
                                <img src={"/img/logo" + this.context.rootState.nombre + ".png"} alt="" width={'50%'}/>
                            </div>
                        </div>
                        <div className='col-12 mt-3' hidden={!this.state.hideInput}>
                            <button className='btn btnRadius btn-dark px-4 d-flex mx-auto text-white' onClick={() => this.showInput()}>Cambiar</button>
                        </div>
                        <div className="col-12 mt-3" hidden={this.state.hideInput}>
                            <div className="elemento">
                                <label>Seleccione una imagen (.png)</label>
                                <input className='mt-1 form-control' type="file" ref={this.logo} accept="image/png" multiple={false} onChange={this.onChangeImg}/>
                            </div>
                        </div>
                        <div hidden={this.state.hideInput}>
                            <div className="col-12 mt-3 text-center" hidden={this.state.urlImg === ''}>
                                <div className="elemento bg-dark">
                                    <img src={this.state.urlImg} alt="" width={'50%'}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 text-center" hidden={this.state.hideInput}>
                            <button className='btn btnRadius btn-primary text-white px-4 mx-3' style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }} onClick={() => this.hideInput()}>Cancelar</button>
                            <button className='btn btnRadius btn-info text-white px-4 mx-3' style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px' }} onClick={this.cambiarLogo}>Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster/>
            </>
         );
    }
}
 
export default Logo;