import React, { Component } from 'react';
import colores from '../../../css/bootstrap.scss';
import toast, { Toaster } from 'react-hot-toast';
import {MyContext} from '../../../context/AuthContext';
import { MoonLoader } from 'react-spinners';
 
class CorteDiario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fecha: this.formatDate(new Date().toLocaleDateString()),
            dataCorte: [],
            cargando: true
        }
    }
    
    static contextType = MyContext;

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    componentDidMount = async () => {
        this.select();
    }

    select = async () => {
        this.setState({
            cargando: true
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectDatosDia', fecha: this.state.fecha, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/cortes.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                dataCorte: data,
                cargando: false
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getUtilidades = (ingresos, costosventa, gastosoperativos) => {
        let utilidades = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos))
        return utilidades
    }
    
    getDisponible = (ingresos, costosventa, gastosoperativos, repartoutilidades) => {
        let disponible = ingresos - (Math.abs(costosventa) + Math.abs(gastosoperativos) + Math.abs(repartoutilidades))
        return disponible
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    buscar = () => {
        this.select();
    }

    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Number(parte);
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }

    getSalidasEfectivo = () => {
        var total = 0;
        total = Number(this.state.dataCorte.costoVentaEfectivo) + Number(this.state.dataCorte.gastosOperativosEfectivo) + Number(this.state.dataCorte.repartoUtilidadesEfectivo);
        return total
    }

    getSalidas = (datos) => {
        var total = 0;
        total = Number(datos.costoVenta) + Number(datos.gastosOperativos) + Number(datos.repartoUtilidades);
       return total
    }

    getDisponibleCuentas = () => {
        return this.numberWithCommas((Number(this.state.dataCorte.totalIngresos) - Number(this.state.dataCorte.ingresosEfectivo)) -(Number(this.state.dataCorte.totalGastos) - this.getSalidasEfectivo()));
    }
    
    render() { 
        return ( 
            <>
                <div className="col-4 py-0 px-1">
                    <div className="inputGroup">
                        <label htmlFor="fecha">Fecha</label>
                            <input type="date" className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '16px' }} name="fecha" id="fecha" value={this.state.fecha} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-2">
                    <button className='w-100 btnAplicar' onClick={this.buscar}>Aplicar</button>
                </div>
                {
                    this.state.cargando ? 
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="text-start">
                                <MoonLoader color={colores.dark} size={200}/>
                            </div>
                        </div>
                    :
                        <div className='contenido px-3'>
                            <div className="elemento mt-3 bg-white" style={{ color: 'black' }}>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <p className='p-0 py-1 boxShadow bg-primary' style={{ color: 'white', borderRadius: '5px' }}>Ingresos</p>
                                        </div>
                                        <div className="col-7 d-flex align-items-center" style={{ fontSize: '15px' }}>
                                            Efectivo
                                        </div>
                                        <div className="col-5 text-end">
                                            <div className="input-group">
                                                <span className='input-group-text' style={{ height: '30px', color: colores.success, borderColor: colores.success }}>$</span>
                                                <input className='form-control text-end' style={{ height: '30px', borderColor: colores.success, color: colores.success, backgroundColor: 'transparent' }} type="text" readOnly value={this.numberWithCommas(this.state.dataCorte.ingresosEfectivo)}/>
                                            </div>
                                        </div>
                                        {this.state.dataCorte.cuentas && this.state.dataCorte.cuentas.map((e,index) => 
                                            <div className="col-12" key={index} hidden={e.idCuenta === '1'}>
                                                <div className="row">
                                                    <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                                        {e.nombreCuenta}
                                                    </div>
                                                    <div className="col-5 text-end mt-2">
                                                        <div className="input-group">
                                                            <span className='input-group-text' style={{ height: '30px', color: colores.info, borderColor: colores.info }}>$</span>
                                                            <input className='form-control boxShadow text-end' style={{ height: '30px', borderColor: colores.info, color: colores.info, backgroundColor: 'transparent' }} type="text" readOnly value={this.numberWithCommas(e.ingresos)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                            Total Ingresos
                                        </div>
                                        <div className="col-5 text-end mt-2">
                                            <div className="input-group">
                                                <span className='input-group-text' style={{ height: '30px', color: 'black' }}>$</span>
                                                <input className='form-control boxShadow text-end' style={{ height: '30px', color: 'black', backgroundColor: 'transparent' }} type="text" readOnly value={this.numberWithCommas(this.state.dataCorte.totalIngresos)}/>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center mt-3">
                                            <p className='p-0 py-1 boxShadow bg-primary' style={{ color: 'white', borderRadius: '5px' }}>Salidas</p>
                                        </div>
                                        <div className="col-7 d-flex align-items-center" style={{ fontSize: '15px' }}>
                                            Efectivo
                                        </div>
                                        <div className="col-5 text-end">
                                            <div className="input-group">
                                                <span className='input-group-text' style={{ height: '30px', color: colores.red, borderColor: colores.red }}>$</span>
                                                <input className='form-control boxShadow text-end' style={{ height: '30px', borderColor: colores.red, color: colores.red, backgroundColor: 'transparent' }} type="text" readOnly value={this.numberWithCommas(this.getSalidasEfectivo())}/>
                                            </div>
                                        </div>
                                        {this.state.dataCorte.cuentas && this.state.dataCorte.cuentas.map((e,index) => 
                                            <div className="col-12" key={index}>
                                                <div className="row">
                                                    <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                                        {e.nombreCuenta}
                                                    </div>
                                                    <div className="col-5 text-end mt-2">
                                                        <div className="input-group">
                                                            <span className='input-group-text' style={{ height: '30px', color: colores.red, borderColor: colores.red }}>$</span>
                                                            <input className='form-control boxShadow text-end' style={{ height: '30px', borderColor: colores.red, color: colores.red, backgroundColor: 'transparent' }} type="text" readOnly value={this.numberWithCommas(this.getSalidas(e))}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}                            
                                        <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                            Total Salidas
                                        </div>
                                        <div className="col-5 text-end mt-2">
                                            <div className="input-group">
                                                <span className='input-group-text' style={{ height: '30px' }}>$</span>
                                                <input className='form-control boxShadow text-end' style={{ height: '30px', backgroundColor: 'transparent' }} type="text" readOnly value={this.numberWithCommas(this.state.dataCorte.totalGastos)}/>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center mt-3">
                                            <p className='p-0 py-1 boxShadow bg-primary' style={{ color: 'white', borderRadius: '5px' }}>Balances</p>
                                        </div>                                                   
                                        <div className="col-7 d-flex align-items-center" style={{ fontSize: '15px' }}>
                                            Balance Efectivo
                                        </div>
                                        <div className="col-5 text-end">
                                            <div className="input-group">
                                                <span className='input-group-text' style={{ height: '30px' }}>$</span>
                                                <input className='form-control boxShadow text-end' style={{ height: '30px', backgroundColor: 'transparent' }} type="text" readOnly value={this.numberWithCommas(Number(this.state.dataCorte.ingresosEfectivo) - this.getSalidasEfectivo())}/>
                                            </div>
                                        </div>
                                        <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                            Balance Cuentas
                                        </div>
                                        <div className="col-5 text-end mt-2">
                                            <div className="input-group">
                                                <span className='input-group-text' style={{ height: '30px' }}>$</span>
                                                <input className='form-control boxShadow text-end' style={{ height: '30px', backgroundColor: 'transparent' }} type="text" readOnly value={this.getDisponibleCuentas()}/>
                                            </div>
                                        </div>
                                        <div className="col-7 d-flex align-items-center mt-2" style={{ fontSize: '15px' }}>
                                            Balance General
                                        </div>
                                        <div className="col-5 text-end mt-2">
                                            <div className="input-group">
                                                <span className='input-group-text' style={{ height: '30px' }}>$</span>
                                                <input className='form-control boxShadow text-end' style={{ height: '30px', backgroundColor: 'transparent' }} type="text" readOnly value={this.numberWithCommas(Number(this.state.dataCorte.totalIngresos) - Number(this.state.dataCorte.totalGastos))}/>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                }
                <Toaster/>
            </>
         );
    }
}
 
export default CorteDiario;