import React, { Component } from 'react';
import colores from '../../../css/bootstrap.scss';
import toast, { Toaster } from 'react-hot-toast';
import {MyContext} from '../../../context/AuthContext';
import * as XLSX from 'xlsx/xlsx.mjs';
import { MoonLoader } from 'react-spinners';

class Detallado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fechaInicio: this.formatDate(new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString()),
            fechaFin: this.formatDate(new Date().toLocaleDateString()),
            data: [],
            saldo: 0,
            cargando: true
        }
    }
    
    static contextType = MyContext;

    componentDidMount = async () => {
        this.select();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.sucursal !== prevProps.sucursal) {
            this.select();
        }
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    select = async () => {
        this.setState({
            cargando: true
        })
        this.selectSaldo();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectRangoDetalles', desde: this.state.fechaInicio, hasta: this.state.fechaFin, sucursal: this.props.sucursal, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                data: data
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    selectSaldo = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectSaldoDia', fecha: this.state.fechaFin, empresa: this.context.rootState.nombre })
        };
        const response = await fetch('http://regventas.argeos.net/php/DB/registros.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            let total = 0;
            data.data.forEach(element => {
                total = total + Number(element.saldo)
            });
            this.setState({
                saldo: total,
                cargando: false
            })
        }else{
            toast.error('Error al cargar los datos');
            console.log(data.mensaje);
        }
    }

    subtractDays(numOfDays, date = new Date()) {
        date.setDate(date.getDate() - numOfDays);
        return date;
    }

    onChangeDateRange = (values) => {
        this.setState({
            rango: values
        })
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    buscar = () => {
        if (this.state.fechaFin < this.state.fechaInicio) {
            toast.error('La fecha final no puede ser menor a la inicial');
        }else{
            this.select();
        }
    }

    ExportToExcel(type, fn, dl) {
        let fechaInicio = this.state.rango[0].startDate.toLocaleDateString().split('/').reverse().join('-');
        let fechaFin = this.state.rango[0].endDate.toLocaleDateString().split('/').reverse().join('-');
        var elt = document.getElementById('tbl_exporttable_to_xls');
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
        return dl ?
        XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
        XLSX.writeFile(wb, fn || ('Resumen detallado ' + fechaInicio + '/' + fechaFin + '.' + (type || 'xlsx')));
    }

    getTotal = (array) => {
        var total = 0;
        array.forEach(element => {
            total = total + Number(element.monto)
        });
        return total
    }

    getPorcentaje = (total, parte) => {
        total = Number(total);
        parte = Number(parte);
        if (total !== 0 && parte !== 0) {
            return ((parte/total)*100).toFixed(0)
        }
        return 0
    }
    
    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    render() { 
        return ( 
            <>
                <div className={"col-4 py-0 px-1 " + (this.props.sucursal ? 'mt-2' : '')}>
                    <div className="inputGroup">
                        <label htmlFor="desde">Desde</label>
                        <input type="date" id='desde' className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '14px' }} name="fechaInicio" value={this.state.fechaInicio} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-1">
                    <div className="inputGroup">
                        <label htmlFor="hasta">Hasta</label>
                        <input type="date" id='hasta' className='form-control inputUnderline text-center text-white' style={{ borderRadius: '0px', fontSize: '14px' }} name="fechaFin" value={this.state.fechaFin} onChange={this.onChangeValue}/>
                    </div>
                </div>
                <div className="col-4 mt-2 py-0 px-1">
                    <button className='w-100 btnAplicar' onClick={this.buscar}>Aplicar</button>
                </div>
                {
                    this.state.cargando ? 
                        <div className='d-flex justify-content-center mt-5'>
                            <div className="text-start">
                                <MoonLoader color={colores.primary} size={200}/>
                            </div>
                        </div>
                    :
                        <div className='contenido px-3'>
                            <div className="mt-2" style={{ overflowX: 'auto' }}>
                                    <table id="tbl_exporttable_to_xls">
                                        <tbody>
                                            <tr>
                                                <th colSpan={4}>Ingresos</th>
                                            </tr>
                                            <tr>
                                                <th className='thSubheader'>Concepto</th>
                                                <th className='thSubheader'>Monto</th>
                                                <th className='thSubheader'>%</th>
                                                <th className='thSubheader'>Prom. Diario</th>
                                            </tr>
                                            {this.state.data.ingresos && this.state.data.ingresos.map((e, index) => 
                                                <tr key={index} className="text-end">
                                                    <td className='text-start'>{e.nombre}</td>
                                                    <td>${this.numberWithCommas(e.monto)}</td>
                                                    <td>{this.getPorcentaje(this.getTotal(this.state.data.ingresos), e.monto)}%</td>
                                                    <td>${this.numberWithCommas(e.promedio)}</td>
                                                </tr>    
                                            )}
                                            {this.state.data.ingresos && this.state.data.ingresos.length > 0 &&
                                                <tr style={{ fontWeight: '400' }} className='text-end'>
                                                    <td className='text-start'>Total:</td>
                                                    <td colSpan={3} style={{ whiteSpace: 'nowrap' }}>${this.numberWithCommas(this.getTotal(this.state.data.ingresos))}</td>
                                                </tr>
                                            }
                                            {this.state.data.ingresos && this.state.data.ingresos.length > 0 &&
                                                <tr style={{ backgroundColor: '#F4F4F4' }} className='text-end'>
                                                    <td className='text-start'>Porcentaje:</td>
                                                    <td colSpan={3}>{this.getPorcentaje(this.getTotal(this.state.data.ingresos), this.getTotal(this.state.data.ingresos))}%</td>
                                                </tr>
                                            }
                                            <tr style={{ backgroundColor: colores.primary, color: 'white' }} className="tableHeader">
                                                <th colSpan={4}>Costos de Venta</th>
                                            </tr>
                                            <tr>
                                                <th className='thSubheader'>Concepto</th>
                                                <th className='thSubheader'>Monto</th>
                                                <th className='thSubheader'>%</th>
                                                <th className='thSubheader'>Prom. Diario</th>
                                            </tr>
                                            {this.state.data.costosventa && this.state.data.costosventa.map((e, index) => 
                                                <tr key={index} className='text-end'>
                                                    <td className='text-start'>{e.nombre}</td>
                                                    <td colSpan={1}>${this.numberWithCommas(e.monto)}</td>
                                                    <td>{this.getPorcentaje(this.getTotal(this.state.data.costosventa), e.monto)}%</td>
                                                    <td colSpan={1}>${this.numberWithCommas(e.promedio)}</td>
                                                </tr>    
                                            )}
                                            {this.state.data.costosventa && this.state.data.costosventa.length > 0 &&
                                                <tr style={{ fontWeight: '400' }} className='text-end'>
                                                    <td className='text-start'>Total:</td>
                                                    <td colSpan={3}>${this.numberWithCommas(this.getTotal(this.state.data.costosventa))}</td>
                                                </tr>
                                            }
                                            {this.state.data.costosventa && this.state.data.costosventa.length > 0 &&
                                                <tr style={{backgroundColor: '#F4F4F4' }} className='text-end'>
                                                    <td className='text-start'>Porcentaje:</td>
                                                    <td colSpan={3}>{this.getPorcentaje(this.getTotal(this.state.data.ingresos), this.getTotal(this.state.data.costosventa))}%</td>
                                                </tr>
                                            }
                                            <tr style={{ backgroundColor: colores.primary, color: 'white' }} className="tableHeader">
                                                <th colSpan={4}>Gastos Operativos</th>
                                            </tr>
                                            <tr>
                                                <th className='thSubheader'>Concepto</th>
                                                <th className='thSubheader'>Monto</th>
                                                <th className='thSubheader'>%</th>
                                                <th className='thSubheader'>Prom. Diario</th>
                                            </tr>
                                            {this.state.data.gastosoperativos && this.state.data.gastosoperativos.map((e, index) => 
                                                <tr key={index} className='text-end'>
                                                    <td className='text-start'>{e.nombre}</td>
                                                    <td colSpan={1}>${this.numberWithCommas(e.monto)}</td>
                                                    <td>{this.getPorcentaje(this.getTotal(this.state.data.gastosoperativos), e.monto)}%</td>
                                                    <td colSpan={1}>${this.numberWithCommas(e.promedio)}</td>
                                                </tr>    
                                            )}
                                            {this.state.data.gastosoperativos && this.state.data.gastosoperativos.length > 0 &&
                                                <tr style={{ fontWeight: '400' }} className='text-end'>
                                                    <td className='text-start'>Total:</td>
                                                    <td colSpan={3}>${this.numberWithCommas(this.getTotal(this.state.data.gastosoperativos))}</td>
                                                </tr>
                                            }
                                            {this.state.data.gastosoperativos && this.state.data.gastosoperativos.length > 0 &&
                                                <tr style={{ backgroundColor: '#F4F4F4' }} className='text-end'>
                                                    <td className='text-start'>Porcentaje:</td>
                                                    <td colSpan={3}>{this.getPorcentaje(this.getTotal(this.state.data.ingresos), this.getTotal(this.state.data.gastosoperativos))}%</td>
                                                </tr>
                                            }
                                            <tr style={{ backgroundColor: colores.primary, color: 'white' }} className="tableHeader">
                                                <th colSpan={4}>Utilidades</th>
                                            </tr>
                                            {this.state.data.gastosoperativos &&
                                                <tr style={{ fontWeight: '400' }} className='text-end'>
                                                    <td className='text-start'>Monto:</td>
                                                    <td colSpan={3}>${this.numberWithCommas(this.getTotal(this.state.data.ingresos) - this.getTotal(this.state.data.costosventa) - this.getTotal(this.state.data.gastosoperativos))}</td>
                                                </tr>
                                            }
                                            {this.state.data.gastosoperativos &&
                                                <tr style={{ backgroundColor: '#F4F4F4' }} className='text-end'>
                                                    <td className='text-start'>Porcentaje:</td>
                                                    <td colSpan={3}>{this.getPorcentaje(this.getTotal(this.state.data.ingresos), (this.getTotal(this.state.data.ingresos) - this.getTotal(this.state.data.costosventa) - this.getTotal(this.state.data.gastosoperativos)))}%</td>
                                                </tr>
                                            }
                                            <tr style={{ backgroundColor: colores.primary, color: 'white' }} className="tableHeader">
                                                <th colSpan={4}>Reparto de Utilidades</th>
                                            </tr>
                                            <tr>
                                                <th className='thSubheader'>Concepto</th>
                                                <th className='thSubheader'>Monto</th>
                                                <th className='thSubheader'>%</th>
                                                <th className='thSubheader'>Prom. Diario</th>
                                            </tr>
                                            {this.state.data.repartoutilidades && this.state.data.repartoutilidades.map((e, index) => 
                                                <tr key={index} className='text-end'>
                                                    <td className='text-start'>{e.nombre}</td>
                                                    <td>${this.numberWithCommas(e.monto)}</td>
                                                    <td>{this.getPorcentaje(this.getTotal(this.state.data.repartoutilidades), e.monto)}%</td>
                                                    <td>${this.numberWithCommas(e.promedio)}</td>
                                                </tr>    
                                            )}
                                            {this.state.data.repartoutilidades && this.state.data.repartoutilidades.length > 0 &&
                                                <tr style={{ fontWeight: '400' }} className='text-end'>
                                                    <td className='text-start'>Total:</td>
                                                    <td colSpan={3}>${this.numberWithCommas(this.getTotal(this.state.data.repartoutilidades))}</td>
                                                </tr>
                                            }
                                            {this.state.data.repartoutilidades && this.state.data.repartoutilidades.length > 0 &&
                                                <tr style={{ backgroundColor: '#F4F4F4' }} className='text-end'>
                                                    <td className='text-start'>Porcentaje:</td>
                                                    <td colSpan={3}>{this.getPorcentaje(this.getTotal(this.state.data.ingresos), this.getTotal(this.state.data.repartoutilidades))}%</td>
                                                </tr>
                                            }
                                            <tr style={{ backgroundColor: colores.primary, color: 'white' }} className="tableHeader">
                                                <th colSpan={4}>Inversiones</th>
                                            </tr>
                                            <tr>
                                                <th className='thSubheader'>Concepto</th>
                                                <th className='thSubheader' colSpan={2}>Monto</th>
                                                <th className='thSubheader'>%</th>
                                            </tr>
                                            {this.state.data.inversiones && this.state.data.inversiones.map((e, index) => 
                                                <tr key={index} className='text-end'>
                                                    <td className='text-start'>{e.nombre}</td>
                                                    <td colSpan={2}>${this.numberWithCommas(e.monto)}</td>
                                                    <td>{this.getPorcentaje(this.getTotal(this.state.data.inversiones), e.monto)}%</td>
                                                </tr>    
                                            )}
                                            {this.state.data.inversiones && this.state.data.inversiones.length > 0 &&
                                                <tr style={{ fontWeight: '400' }} className='text-end'>
                                                    <td className='text-start'>Total:</td>
                                                    <td colSpan={3}>${this.numberWithCommas(this.getTotal(this.state.data.inversiones))}</td>
                                                </tr>
                                            }
                                            {this.state.data.inversiones && this.state.data.inversiones.length > 0 &&
                                                <tr style={{ backgroundColor: '#F4F4F4' }} className='text-end'>
                                                    <td className='text-start'>Porcentaje:</td>
                                                    <td colSpan={3}>{this.getPorcentaje(this.getTotal(this.state.data.ingresos), this.getTotal(this.state.data.inversiones))}%</td>
                                                </tr>
                                            }
                                            <tr style={{ backgroundColor: colores.primary, color: 'white' }} className="tableHeader">
                                                <th colSpan={4}>Balance</th>
                                            </tr>
                                            {this.state.data.gastosoperativos &&
                                                <tr style={{ backgroundColor: '#EAFFE8'}} className='text-end'>
                                                    <td className='text-start'>Monto:</td>
                                                    <td colSpan={3}>${this.numberWithCommas(this.getTotal(this.state.data.ingresos) - this.getTotal(this.state.data.costosventa) - this.getTotal(this.state.data.gastosoperativos) - this.getTotal(this.state.data.repartoutilidades) - this.getTotal(this.state.data.inversiones))}</td>
                                                </tr>
                                            }
                                            {this.state.data.gastosoperativos &&
                                                <tr style={{ backgroundColor: '#F4F4F4' }} className='text-end'>
                                                    <td className='text-start'>Porcentaje:</td>
                                                    <td colSpan={3}>{this.getPorcentaje(this.getTotal(this.state.data.ingresos), (this.getTotal(this.state.data.ingresos) - this.getTotal(this.state.data.costosventa) - this.getTotal(this.state.data.gastosoperativos) - this.getTotal(this.state.data.repartoutilidades) - this.getTotal(this.state.data.inversiones)))}%</td>
                                                </tr>
                                            }
                                            <tr style={{ backgroundColor: colores.primary, color: 'white' }} className="tableHeader">
                                                <th colSpan={4}>Disponible</th>
                                            </tr>
                                            <tr style={{ backgroundColor: '#C5DEE5' }} className='text-end'>
                                                <td className='text-start'>Monto:</td>
                                                <td className='text-start' style={{ color: '#8fa1ad' }}>al {this.state.fechaFin}</td>
                                                <td colSpan={2}>${this.numberWithCommas(this.state.saldo)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                            <div>
                                <button onClick={() => this.ExportToExcel('xlsx')} className='btn mt-1 mb-2' style={{ padding: '0px', textDecoration: 'underline', fontSize: '14px', color: 'gray' }}>Descargar tabla</button>
                            </div>
                        </div>
                }
                <Toaster/>
            </>
         );
    }
}
 
export default Detallado;